import genericReload from '../components/genericReload';
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import * as basics from "../components/basics.js";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: '',
    reload: 0,
    idUser: 0,
    user: '',
    typeUser: '',
    idCenter: 0,
    centers: [],
    allCenters: [],
    clients: [],
    workshops: [],
    allWorkshops: [],
    professionals: [],
    allProfessionals: [],
    companies: [],
    messages: [],
    specialties: [{id: 1, name: "Nutrición"},{id: 2, name: "Psicología"},{id: 3, name: "Fisioterapia"},{id: 4, name: "Entrenamiento"}],
    pass: [],
    interestedIn: [],
    reasons: [],
    typeClient: [],
    typeCompany: [],
    chefs: [],
    calendars: [],
    professionalsTypes: [{value: "admin", name: "Administrador"},{value: "coord", name: "Coordinador"},{value: "nutricionista", name: "Nutricionista"},{value: "psicologo", name: "Psicólogo"},{value: "fisioterapeuta", name: "Fisioterapeuta"},{value: "entrenador", name: "Entrenador"}],
    filterCalendarPro: 0,
    filterCalendarCenter: 0,
    filterCalendarSpe: 0,
    filterCalendarWork: 0,
    clientSelected: null, // Client selected on Clientes's view to assign a new appointment in calendar
    keepAlive: true,
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      paths: ['auth', 'idUser', 'user', 'idCenter', 'typeUser', 'centers', 'filterCalendarWork', 'filterCalendarSpe'],
    }),
  ],
  mutations: {
    modifyAuth(state, newAuth){
      state.auth = newAuth
    },
    modifyClientSelected(state, newValue){
      state.clientSelected = newValue
    },
    modifyUser(state, newId){
      state.idUser = newId;
    },
    modifyNameUser(state, newName){
      state.user = newName;
    },
    modifyTypeUser(state, newType){
      state.typeUser = newType;
    },
    modifyActualCenter(state, newIdCenter){
      state.idCenter = newIdCenter;
    },
    modifyKeepAlive(state, val){
      state.keepAlive = val;
    },
    modifyReload(state, val){
      state.reload = val;
    },
    addCenter(state, newCenter){
      state.centers.push({"id": newCenter.id, "name": newCenter.name});
    },
    addPatient(state, newClient){
      state.clients.push(newClient);
      state.clients = state.clients.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    addWorkshop(state, newWorkshop){
      state.workshops.push(newWorkshop);
      state.workshops = state.workshops.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    addInterestedIn(state, newItem){
      state.interestedIn.push(newItem);
      state.interestedIn = state.interestedIn.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    addReason(state, newItem){
      state.reasons.push(newItem);
      state.reasons = state.reasons.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    addTypeClient(state, newItem){
      state.typeClient.push(newItem);
      state.typeClient = state.typeClient.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    addTypeCompany(state, newItem){
      state.typeCompany.push(newItem);
      state.typeCompany = state.typeCompany.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    }
  },
  actions: {
    async loadData(){
      if(this.state.auth != ''){
        await this.dispatch("getCentersOfProfessional");
        await this.dispatch("getCenters");
        await this.dispatch("getClients");
        await this.dispatch("getProfessionals");
        await this.dispatch("getCompanies");
        await this.dispatch("getWorkshops");
        // await this.dispatch("getMessages");
        await this.dispatch("getPass");
        await this.dispatch("getInterestedIn");
        await this.dispatch("getReasons");
        await this.dispatch("getTypeClient");
        await this.dispatch("getTypeCompany");
        await this.dispatch("getChefs");
        await this.dispatch("getCalendars");
        if(this.state.idCenter!=0){
          this.commit("modifyActualCenter", this.state.idCenter);
        }else{
          if(this.state.centers[0]){
            this.commit("modifyActualCenter", this.state.centers[0].id);
          }
        }
      }
    },
    async getCentersOfProfessional(){
      const response = await genericReload(this.state.auth, `api/centers/?desactivado=1&idProfesional=${this.state.idUser}`);
      if (response.status === 200) {
        this.state.centers = await response.json();
      }
    },
    async getCenters(){
      const response = await genericReload(this.state.auth, `api/centers/`);
      if (response.status === 200) {
        this.state.allCenters = await response.json();
      }
    },
    async getClients(){
      const response = await genericReload(this.state.auth, 'api/client');
      if (response.status === 200) {
        this.state.clients = await response.json();
        for (let i = 0; i < this.state.clients.length; i++) {
          this.state.clients[i].nombreRA = basics.removeAccents(this.state.clients[i].nombre);
          this.state.clients[i].apellidosRA = basics.removeAccents(this.state.clients[i].apellidos);
        }
      }
    },
    async getClientsByCenter(){
      const response = await genericReload(this.state.auth, 'api/client?idCentro='+this.state.idCenter);
      if (response.status === 200) {
        return await response.json();
      }
    },
    async getProfessionals(){
      const response = await genericReload(this.state.auth, 'api/user?desactivado=1');
      if (response.status === 200) {
        let json = await response.json();
        this.state.allProfessionals = json;
        this.state.professionals = json.filter(p => p.desactivado == 0);
      }
    },
    async getCompanies(){
      const response = await genericReload(this.state.auth, 'api/company');
      if (response.status === 200) {
        this.state.companies = await response.json();
      }
    },
    async getWorkshops(){
      const response = await genericReload(this.state.auth, 'api/workshop?desactivado=1');
      if (response.status === 200) {
        let json = await response.json();
        // this.state.workshops = json;
        // json = json.sort((a,b)=>new Date(b.fecha).getTime()-new Date(a.fecha).getTime())
        // for (let i = 0; i < json.length; i++) {
        //   if(json[i].fecha.includes("-")){
        //     let [fecha, hora] = json[i].fecha.split(" ");
        //     json[i].fecha = basics.formatDate(fecha, "dd/mm/yyyy")+" "+hora;
        //   }
        // }
        json.forEach(workshop => {
          const [fecha, hora] = workshop.fecha.split(" ");
          const formattedDate = `${fecha}T${hora}`;
          workshop.dateToOrder = new Date(formattedDate);
          workshop.fecha = basics.formatDate(fecha, "dd/mm/yyyy")+" "+hora;
        });
    
        json = json.sort((a, b) => a.dateToOrder - b.dateToOrder);

        this.state.allWorkshops = json.reverse();
        this.state.workshops = json.filter(workshop => workshop.desactivado == 0);
      }
    },
    // async getMessages(){
    //   const response = await genericReload(this.state.auth, 'api/message');
    //   if (response.status === 200) {
    //     this.state.messages = await response.json();
    //     this.state.messages = this.state.messages.filter(message => message.titulo.indexOf("Valoración")>-1);
    //   }
    // },
    async getPass(){
      const response = await genericReload(this.state.auth, 'api/prices');
      if (response.status === 200) {
        this.state.pass = await response.json();
      }
    },
    async getInterestedIn(){
      const response = await genericReload(this.state.auth, 'api/interest');
      if (response.status === 200) {
        this.state.interestedIn = await response.json();
      }
    },
    async getReasons(){
      const response = await genericReload(this.state.auth, 'api/reason');
      if (response.status === 200) {
        this.state.reasons = await response.json();
      }
    },
    async getTypeClient(){
      const response = await genericReload(this.state.auth, 'api/typeClient');
      if (response.status === 200) {
        this.state.typeClient = await response.json();
      }
    },
    async getTypeCompany(){
      const response = await genericReload(this.state.auth, 'api/typeCompany');
      if (response.status === 200) {
        this.state.typeCompany = await response.json();
      }
    },
    async getChefs(){
      const response = await genericReload(this.state.auth, 'api/chef');
      if (response.status === 200) {
        this.state.chefs = await response.json();
      }
    },
    async getCalendars(){
      const response = await genericReload(this.state.auth, 'api/gcalendar');
      if (response.status === 200) {
        this.state.calendars = await response.json();
      }
    },
  },
  getters: {
    workshopById: (state) => (id) => {
      return state.allWorkshops.find(workshop => workshop.id === id);
    },
    workshopByName: (state) => (name) => {
      return state.allWorkshops.find(workshop => workshop.nombre === name);
    },
    workshopByNameAndDate: (state) => (name, date) => {
      return state.allWorkshops.find(workshop => workshop.nombre === name && workshop.fecha.split(" ")[0] === date);
    },
    workshopByCenter: (state) => (idCenter) => {
      return state.workshops.filter(workshop => workshop.idCentro === idCenter);
    },
    individualPass: (state) => (idCenter) => {
      return state.pass.find(pass => pass.nutricion === 1 && pass.fisioterapia === 0 && pass.psicologia === 0 && pass.entrenamiento === 0 && pass.center === idCenter);
    },
    passById: (state) => (id) => {
      return state.pass.find(pass => pass.id === id);
    },
    passByCenter: (state) => (idCenter) => {
      return state.pass.filter(pass => pass.idCentro === idCenter);
    },
    clientById: (state) => (id) => {
      return state.clients.find(client => client.id === id);
    },
    clientByName: (state) => (name, surname) => {
      return state.clients.find(client => client.nombre.toUpperCase() === name && client.apellidos.toUpperCase() === surname);
    },
    centerById: (state) => (id) => {
      return state.allCenters.find(center => center.id === id);
    },
    professionalById: (state) => (id) => {
      return state.allProfessionals.find(professional => professional.id === id);
    },
    companyById: (state) => (id) => {
      return state.companies.find(company => company.id === id);
    },
    specialtiesById: (state) => (id) => {
      return state.specialties.find(specialtie => specialtie.id === id);
    },
    interestedById: (state) => (id) => {
      return state.interestedIn.find(interes => interes.id === id);
    },
    // messagesById: (state) => (id) => {
    //   return state.messages.find(message => message.id === id);
    // },
  }
})

<template>
  <div>
    <v-layout justify-center>
      <v-dialog v-model="dialog" persistent class="container">
        <v-card>
          <v-card-title>{{ title }}</v-card-title>
          <v-card-text>
            <div v-if="show == 'citas'">
              <v-form ref="formCitas">
                <v-container>
                  <v-layout>
                    <v-flex xs3>
                      <v-menu
                        v-model="menuDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateFormatted"
                            label="Fecha"
                            prepend-icon="mdi-calendar-range"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('date')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="date"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="time"
                        label="Hora inicio"
                        type="time"
                        @change="timeAuto"
                        aria-required="true"
                      ></v-text-field>
                      <!-- <v-menu
                        ref="menuTime"
                        v-model="menuTime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="time"
                            label="Hora inicio"
                            prepend-icon="mdi-clock-outline"
                            v-bind="attrs"
                            v-on="on"
                            @change="timeAuto"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menuTime"
                          v-model="time"
                          format="24hr"
                          full-width
                          @click:minute="$refs.menuTime.save(time)"
                        ></v-time-picker>
                      </v-menu> -->
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="timeEnd"
                        label="Hora fin"
                        type="time"
                        aria-required="true"
                      ></v-text-field>
                      <!-- <v-menu
                        ref="menuTimeEnd"
                        v-model="menuTimeEnd"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="timeEnd"
                            label="Hora fin"
                            prepend-icon="mdi-clock-outline"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menuTimeEnd"
                          v-model="timeEnd"
                          format="24hr"
                          full-width
                          @click:minute="$refs.menuTime.save(timeEnd)"
                        ></v-time-picker>
                      </v-menu> -->
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs2>
                      <v-checkbox
                        v-model="remember"
                        label="Recordar 24h antes de la cita"
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="showClient == 'select'">
                    <v-flex xs11>
                      <v-autocomplete
                        v-model="patient"
                        :items="this.$store.state.clients"
                        :item-text="
                          (item) =>
                            `${item.apellidosRA.toUpperCase()}, ${item.nombreRA.toUpperCase()}`
                        "
                        item-value="id"
                        label="Paciente"
                        prepend-icon="mdi-account"
                        @blur="loadLastAppointment"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs1>
                      <v-btn
                        v-on:click="showClient = 'input'"
                        text
                        class="botonLHSmini"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="showClient == 'input'">
                    <v-flex xs3>
                      <v-text-field
                        label="Nombre"
                        v-model="patientName"
                        prepend-icon="mdi-account"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Apellidos"
                        v-model="patientSurname"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Teléfono"
                        v-model="patientPhone"
                        type="tel"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Email"
                        v-model="patientEmail"
                        type="email"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs1>
                      <v-btn
                        v-on:click="showClient = 'select'"
                        text
                        class="botonLHSmini"
                      >
                        <v-icon>mdi-account-group</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs6>
                      <v-select
                        v-model="professional"
                        :items="this.$store.state.professionals"
                        item-text="nombre"
                        item-value="id"
                        label="Profesional"
                        prepend-icon="mdi-tooltip-account"
                        @change="loadCenters"
                      ></v-select>
                    </v-flex>
                    <v-flex xs6>
                      <v-select
                        v-model="center"
                        :items="this.itemsCenter"
                        item-text="nombre"
                        item-value="id"
                        label="Centro"
                        prepend-icon="mdi-office-building-marker"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="professional != this.$store.state.idUser">
                    <v-flex xs12>
                      <v-select
                        v-model="alert"
                        :items="[
                          'No avisar',
                          'Avisar vía SMS',
                          'Avisar vía email',
                        ]"
                        label="Avisar al profesional"
                        prepend-icon="mdi-tooltip-account"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12>
                      <v-select
                        v-model="specialty"
                        :items="this.$store.state.specialties"
                        item-text="name"
                        item-value="name"
                        label="Tipo de cita"
                        prepend-icon="mdi-format-list-bulleted-type"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12>
                      <v-select
                        v-model="mode"
                        :items="modes"
                        label="Modalidad de cita"
                        prepend-icon="mdi-format-list-bulleted-type"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs6>
                      <v-select
                        v-model="recurrency"
                        :items="recurrencyItems"
                        label="Recurrencia de la cita"
                        prepend-icon="mdi-reload"
                      ></v-select>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs3>
                      <v-menu
                        v-model="menuDate2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateRecurrencyFormatted"
                            label="Hasta el día"
                            prepend-icon="mdi-calendar-range"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('dateRecurrency')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="dateRecurrency"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-if="show == 'bloqueos'">
              <v-form ref="formBloqueos">
                <v-container>
                  <v-layout>
                    <v-flex xs3>
                      <v-menu
                        v-model="menuDateDesde"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateFormattedDesde"
                            label="Inicio de bloqueo"
                            prepend-icon="mdi-calendar-range"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('dateDesde'); copyDate()"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="dateDesde"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="time"
                        label="Hora inicio"
                        type="time"
                        aria-required="true"
                      ></v-text-field>
                      <!-- <v-menu
                        ref="menuTime"
                        v-model="menuTime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="time"
                            label="Hora inicio"
                            prepend-icon="mdi-clock-outline"
                            v-bind="attrs"
                            v-on="on"
                            @change="timeAuto"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menuTime"
                          v-model="time"
                          format="24hr"
                          full-width
                          @click:minute="$refs.menuTime.save(time)"
                        ></v-time-picker>
                      </v-menu> -->
                    </v-flex>
                    <v-flex xs3>
                      <v-menu
                        v-model="menuDateHasta"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateFormattedHasta"
                            label="Fin de bloqueo"
                            prepend-icon="mdi-calendar-range"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('dateHasta')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="dateHasta"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="timeEnd"
                        label="Hora fin"
                        type="time"
                        aria-required="true"
                      ></v-text-field>
                      <!-- <v-menu
                        ref="menuTimeEnd"
                        v-model="menuTimeEnd"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="timeEnd"
                            label="Hora fin"
                            prepend-icon="mdi-clock-outline"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menuTimeEnd"
                          v-model="timeEnd"
                          format="24hr"
                          full-width
                          @click:minute="$refs.menuTime.save(timeEnd)"
                        ></v-time-picker>
                      </v-menu> -->
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs9>
                      <v-select
                        v-model="professional"
                        :items="this.$store.state.professionals"
                        item-text="nombre"
                        item-value="id"
                        label="Profesional"
                        prepend-icon="mdi-tooltip-account"
                        @change="loadCenters"
                      ></v-select>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs2>
                      <v-checkbox
                        v-model="fullDay"
                        label="Marcar día completo"
                        @change="fullDayChecked"
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12 sm6>
                      <v-text-field
                        label="Título de bloqueo"
                        v-model="titleBlock"
                        prepend-icon="mdi-calendar-text"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        label="Más información"
                        v-model="textBlock"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs6>
                      <v-select
                        v-model="recurrency"
                        :items="recurrencyItems"
                        label="Recurrencia del bloqueo"
                        prepend-icon="mdi-reload"
                      ></v-select>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs3>
                      <v-menu
                        v-model="menuDate2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateRecurrencyFormatted"
                            label="Hasta el día"
                            prepend-icon="mdi-calendar-range"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('dateRecurrency')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="dateRecurrency"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'pesos'">
              <v-form ref="formPesos">
                <v-container>
                  <v-layout>
                    <v-flex xs4>
                      <v-menu
                        v-model="menuDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="registroDateFormatted"
                            label="Fecha"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('registro')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="registro.fecha"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Altura"
                        v-model="registro.altura"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Peso"
                        v-model="registro.peso"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="IMC"
                        v-model="registro.imc"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Metabolismo basal"
                        v-model="registro.metabolismobasal"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <!-- <v-flex xs4>
                      <v-select
                        v-model="registro.tipocuerpo"
                        :items="tiposCuerpo"
                        label="Tipo de cuerpo"
                      ></v-select>
                    </v-flex> -->
                    <v-flex xs2>
                      <v-text-field
                        label="Masa grasa"
                        v-model="registro.masagrasa"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="% Masa grasa"
                        v-model="registro.pmasagrasa"
                        type="number"
                        min="0"
                        max="100"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Masa libre de grasa"
                        v-model="registro.masalibregrasa"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="% Masa libre de grasa"
                        v-model="registro.pmasalibregrasa"
                        type="number"
                        min="0"
                        max="100"
                      >
                      </v-text-field>
                    </v-flex>
                    <!-- </v-layout>
                  <v-layout> -->
                    <v-flex xs2>
                      <v-text-field
                        label="Agua corporal"
                        v-model="registro.aguacorporaltotal"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="% ACT"
                        v-model="registro.pact"
                        type="number"
                        min="0"
                        max="100"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12>
                      <v-text-field
                        label="Comentario"
                        v-model="registro.comentario"
                        counter
                        maxlength="140"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'nuevoTaller'">
              <v-form ref="formNuevoTaller">
                <v-container>
                  <v-layout>
                    <v-flex xs2>
                      <v-menu
                        v-model="menuDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="nuevoTallerDateFormatted"
                            label="Fecha"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('nuevoTaller')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="nuevoTaller.fecha"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs3>
                      <v-select
                        v-model="nuevoTaller.centro"
                        :items="this.$store.state.centers"
                        item-text="nombre"
                        item-value="id"
                        label="Centro"
                        @change="getWorkshops"
                      ></v-select>
                    </v-flex>
                    <v-flex xs3>
                      <v-select
                        v-model="nuevoTaller.profesional"
                        :items="this.$store.state.professionals"
                        label="Vendedor"
                        item-text="nombre"
                        item-value="id"
                      ></v-select>
                    </v-flex>
                    <v-flex xs4>
                      <v-select
                        v-model="nuevoTaller.tipo"
                        label="Tipo de compra"
                        :items="workshops"
                        :item-text="(item) => `${item.date} | ${item.name}`"
                        item-value="id"
                        @change="getPriceWorkshops"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3>
                      <v-text-field
                        label="Precio"
                        v-model="nuevoTaller.precio"
                        type="number"
                        readonly
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Descuento %"
                        v-model="nuevoTaller.pdesc"
                        type="number"
                        min="0"
                        @change="calculaPrecioTaller('nuevoTaller')"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Descuento €"
                        v-model="nuevoTaller.desc"
                        type="number"
                        min="0"
                        @change="calculaPrecioTaller('nuevoTaller')"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Total"
                        v-model="nuevoTaller.total"
                        type="number"
                        min="0"
                        @change="calculaPrecioTaller('nuevoTaller')"
                        readonly
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <!-- <v-layout>
                    <v-flex xs4>
                      <v-select
                        v-model="nuevoTaller.pago"
                        label="Tipo de pago"
                        :items="[
                          'Cartera',
                          'Efectivo',
                          'Tarjeta',
                          'Tarjeta regalo',
                          'Transferencia',
                          'Paypal',
                          'Pendiente de pago',
                          'Invitación',
                          'Sin coste',
                        ]"
                        @change="invitacion('nuevoTaller')"
                      ></v-select>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-if="nuevoTaller.pago == 'Tarjeta regalo'"
                        label="Código"
                        v-model="nuevoTaller.codigo"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout> -->
                  <v-layout>
                    <v-flex xs12>
                      <v-switch
                        v-model="nuevoTaller.pagoSimple"
                        inset
                        :label="`Modalidad de pago: ${stringPago}`"
                        @change="modoPagoToString('nuevoTaller')"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="nuevoTaller.pagoSimple">
                    <v-flex xs4>
                      <v-select
                        v-model="nuevoTaller.pago"
                        label="Tipo de pago"
                        :items="[
                          'Cartera',
                          'Efectivo',
                          'Tarjeta',
                          'Tarjeta regalo',
                          'Transferencia',
                          'Paypal',
                          'Pendiente de pago',
                          'Invitación',
                          'Sin coste',
                        ]"
                        @change="invitacion('nuevoTaller')"
                      ></v-select>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-if="nuevoTaller.pago == 'Tarjeta regalo'"
                        label="Código"
                        v-model="nuevoTaller.codigo"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="!nuevoTaller.pagoSimple">
                    <v-flex xs1>
                      <v-text-field
                        label="Cartera"
                        v-model="nuevoTaller.cartera"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Código"
                        v-model="nuevoTaller.codigo"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs1>
                      <v-text-field
                        label="Efectivo"
                        v-model="nuevoTaller.efectivo"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field
                        label="Tarjeta"
                        v-model="nuevoTaller.tarjeta"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field
                        label="Tarjeta regalo"
                        v-model="nuevoTaller.tarjetaRegalo"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field
                        label="Transferencia"
                        v-model="nuevoTaller.transferencia"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field
                        label="Paypal"
                        v-model="nuevoTaller.paypal"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Pendiente de pago"
                        v-model="nuevoTaller.pendiente"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field
                        label="Invitación"
                        v-model="nuevoTaller.invitacion"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'precio'">
              <v-form ref="formPrecio">
                <v-container>
                  <v-layout>
                    <v-flex xs5>
                      <v-text-field
                        v-model="precio.nombre"
                        label="Nombre"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="precio.precio"
                        label="Precio"
                        type="number"
                        min="0"
                        step="0.5"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="precio.nutricion"
                        label="Nutrición"
                        type="number"
                        min="0"
                        step="1"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="precio.psicologia"
                        label="Psicología"
                        type="number"
                        min="0"
                        step="1"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="precio.fisioterapia"
                        label="Fisioterapia"
                        type="number"
                        min="0"
                        step="1"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="precio.entrenamiento"
                        label="Entrenamiento"
                        type="number"
                        min="0"
                        step="1"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'nuevoBono'">
              <v-form ref="formNuevoBono">
                <v-container>
                  <v-layout>
                    <v-flex xs2>
                      <v-menu
                        v-model="menuDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="nuevoBonoDateFormatted"
                            label="Fecha"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('nuevoBono')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="nuevoBono.fecha"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs3>
                      <v-select
                        v-model="nuevoBono.centro"
                        :items="this.$store.state.centers"
                        item-text="nombre"
                        item-value="id"
                        label="Centro"
                        @change="getPass"
                      ></v-select>
                    </v-flex>
                    <v-flex xs3>
                      <v-select
                        v-model="nuevoBono.profesional"
                        :items="this.$store.state.professionals"
                        label="Vendedor"
                        item-text="nombre"
                        item-value="id"
                      ></v-select>
                    </v-flex>
                    <v-flex xs4>
                      <v-select
                        v-model="nuevoBono.tipo"
                        label="Tipo de compra"
                        :items="pass"
                        item-text="name"
                        item-value="id"
                        @change="getPrice"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3>
                      <v-text-field
                        label="Precio"
                        v-model="nuevoBono.precio"
                        type="number"
                        readonly
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Descuento %"
                        v-model="nuevoBono.pdesc"
                        type="number"
                        min="0"
                        @change="calculaPrecioBono"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Descuento €"
                        v-model="nuevoBono.desc"
                        type="number"
                        min="0"
                        @change="calculaPrecioBono"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Total"
                        v-model="nuevoBono.total"
                        type="number"
                        min="0"
                        @change="calculaPrecioBono"
                        readonly
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12>
                      <v-switch
                        v-model="nuevoBono.pagoSimple"
                        inset
                        :label="`Modalidad de pago: ${stringPago}`"
                        @change="modoPagoToString('nuevoBono')"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="nuevoBono.pagoSimple">
                    <v-flex xs4>
                      <v-select
                        v-model="nuevoBono.pago"
                        label="Tipo de pago"
                        :items="[
                          'Cartera',
                          'Efectivo',
                          'Tarjeta',
                          'Tarjeta regalo',
                          'Transferencia',
                          'Paypal',
                          'Pendiente de pago',
                          'Invitación',
                          'Sin coste',
                        ]"
                        @change="invitacion('nuevoBono')"
                      ></v-select>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-if="nuevoBono.pago == 'Tarjeta regalo'"
                        label="Código"
                        v-model="nuevoBono.codigo"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="!nuevoBono.pagoSimple">
                    <v-flex xs1>
                      <v-text-field
                        label="Cartera"
                        v-model="nuevoBono.cartera"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Código"
                        v-model="nuevoBono.codigo"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs1>
                      <v-text-field
                        label="Efectivo"
                        v-model="nuevoBono.efectivo"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field
                        label="Tarjeta"
                        v-model="nuevoBono.tarjeta"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field
                        label="Tarjeta regalo"
                        v-model="nuevoBono.tarjetaRegalo"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field
                        label="Transferencia"
                        v-model="nuevoBono.transferencia"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field
                        label="Paypal"
                        v-model="nuevoBono.paypal"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Pendiente de pago"
                        v-model="nuevoBono.pendiente"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field
                        label="Invitación"
                        v-model="nuevoBono.invitacion"
                        type="number"
                        min="0"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'editEventLog'">
              <v-form ref="formEditEventLog">
                <v-container>
                  <v-layout>
                    <v-flex xs2>
                      <v-menu
                        v-model="menuDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editEventLogDateFormatted"
                            label="Fecha"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('editEventLog')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="editEventLog.fecha"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs4>
                      <v-text-field
                        label="Compra"
                        v-model="editEventLog.compra"
                        type="text"
                        readonly
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field
                        label="Precio"
                        v-model="editEventLog.precio"
                        type="text"
                        min="0"
                        readonly
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-select
                        v-model="editEventLog.pago"
                        label="Tipo de pago"
                        :items="[
                          'Cartera',
                          'Efectivo',
                          'Tarjeta',
                          'Tarjeta regalo',
                          'Transferencia',
                          'Paypal',
                          'Pendiente de pago',
                          'Invitación',
                          'Sin coste',
                        ]"
                        @change="invitacion('editEventLog')"
                      ></v-select>
                    </v-flex>
                    <v-flex xs2>
                      <v-select
                        v-model="editEventLog.profesional"
                        :items="this.$store.state.professionals"
                        item-text="nombre"
                        item-value="id"
                        label="Profesional"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3>
                      <v-text-field
                        v-if="editEventLog.pago == 'Tarjeta regalo'"
                        label="Código"
                        v-model="editEventLog.codigo"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12>
                      <v-text-field
                        label="Comentario"
                        v-model="editEventLog.comentario"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'sesionIndividual'">
              <v-form ref="formSesionIndividual">
                <v-container>
                  <v-layout>
                    <v-flex xs2>
                      <v-menu
                        v-model="menuDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="sesionIndividualDateFormatted"
                            label="Fecha"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('sesionIndividual')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="sesionIndividual.fecha"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs5>
                      <v-select
                        v-model="sesionIndividual.centro"
                        :items="this.$store.state.centers"
                        item-text="nombre"
                        item-value="id"
                        label="Centro"
                      ></v-select>
                    </v-flex>
                    <v-flex xs5>
                      <v-select
                        v-model="sesionIndividual.profesional"
                        :items="this.$store.state.professionals"
                        item-text="nombre"
                        item-value="id"
                        label="Vendedor"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3>
                      <v-text-field
                        label="Precio"
                        v-model="sesionIndividual.precio"
                        type="number"
                        :readonly="this.show != 'sesionIndividual'"
                        @change="calculaPrecio"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Descuento %"
                        v-model="sesionIndividual.pdesc"
                        type="number"
                        min="0"
                        @change="calculaPrecio"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Descuento €"
                        v-model="sesionIndividual.desc"
                        type="number"
                        min="0"
                        @change="calculaPrecio"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        label="Total"
                        v-model="sesionIndividual.total"
                        type="number"
                        min="0"
                        readonly
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'newDoc'">
              <v-form ref="formSesionNewDoc">
                <v-container>
                  <v-layout>
                    <v-flex xs6>
                      <v-file-input
                        @change="copyName"
                        v-model="fileUploaded"
                        accept="image/*,audio/*,video/*,text/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                        label="Haz clic para seleccionar el fichero a subir"
                      ></v-file-input>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        label="Nombre"
                        v-model="nombreNewDoc"
                        placeholder="Nombre del documento"
                        required
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs6>
                      <v-select
                        v-model="permisosNewDoc"
                        :items="permisos"
                        item-text="name"
                        item-value="name"
                        label="Permisos para"
                        multiple
                        required
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'cargaCSV'">
              <v-form ref="formSesionCargaCSV">
                <v-container>
                  <v-layout>
                    <v-flex xs12>
                      <v-file-input
                        v-model="fileUploadedCSV"
                        accept=".csv"
                        label="Haz clic para seleccionar el fichero a subir"
                      ></v-file-input>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12>
                      <v-switch
                        v-model="switchCreaCliente"
                        label="Crear cliente en caso de que no exista"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
            <div v-else-if="show == 'consumirSesion'">
              {{ text }}
              <v-layout>
                <v-flex xs2>
                  <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="consumirSesionDateFormatted"
                        label="Fecha"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="changeDateFormatted('consumirSesion')"
                      locale="es"
                      :first-day-of-week="1"
                      v-model="fechaConsumirSesion"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs6>
                  <v-select
                    v-model="tipoSesion"
                    :items="types"
                    label="Tipo de sesión a consumir"
                    required
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12>
                  <v-textarea
                    v-model="notaSesion"
                    label="Observaciones"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </div>
            <div v-else-if="show == 'consumirSesionCompartida'">
              {{ text }}
              <v-layout>
                <v-flex xs2>
                  <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="consumirSesionCompartidaDateFormatted"
                        label="Fecha"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="changeDateFormatted('consumirSesionCompartida')"
                      locale="es"
                      :first-day-of-week="1"
                      v-model="fechaConsumirSesionCompartida"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs6>
                  <v-autocomplete
                    v-model="clienteSesion"
                    :items="this.$store.state.clients"
                    :item-text="
                      (item) =>
                        `${item.apellidosRA.toUpperCase()}, ${item.nombreRA.toUpperCase()}`
                    "
                    item-value="id"
                    label="Cliente que comparte sesión"
                    required
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs4>
                  <v-select
                    v-model="tipoSesion"
                    :items="types"
                    label="Tipo de sesión a consumir"
                    required
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12>
                  <v-textarea
                    v-model="notaSesion"
                    label="Observaciones"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </div>
            <div v-else-if="show == 'manualLoad'">
              <v-layout v-for="item in data" :key="item">
                <v-flex xs12>
                  <v-autocomplete
                    :id="item.toString()"
                    :items="clients"
                    :item-text="
                      (item) =>
                        `${item.apellidosRA.toUpperCase()}, ${item.nombreRA.toUpperCase()}`
                    "
                    :label="(item + 1).toString()"
                  >
                  </v-autocomplete>
                </v-flex>
              </v-layout>
            </div>
            <div v-else-if="show == 'valora'">
              <v-layout>
                <v-flex xs4>
                  <v-select
                    v-model="msgValora"
                    :items="valoraciones"
                    item-text="titulo"
                    item-value="id"
                    label="Mensaje a enviar"
                  ></v-select>
                </v-flex>
              </v-layout>
            </div>
            <div v-else-if="show == 'edicionSesiones'">
              <v-layout>
                <v-flex xs3>
                  <v-text-field
                    v-model="nutricionEdita"
                    type="number"
                    step="1"
                    min="0"
                    label="Nutrición"
                  ></v-text-field>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="psicologiaEdita"
                    type="number"
                    step="1"
                    min="0"
                    label="Psicología"
                  ></v-text-field>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="fisioterapiaEdita"
                    type="number"
                    step="1"
                    min="0"
                    label="Fisioterapia"
                  ></v-text-field>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="entrenamientoEdita"
                    type="number"
                    step="1"
                    min="0"
                    label="Entrenamiento"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </div>
            <div v-else-if="show == 'newClientTaller'">
              <v-form ref="formNewClientTaller">
                <v-layout>
                  <v-flex xs2>
                    <v-text-field
                      autocomplete="off"
                      v-model="nombreClienteTaller"
                      label="Nombre *"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      autocomplete="off"
                      v-model="apellidosClienteTaller"
                      label="Apellidos *"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="fnacClienteTaller"
                      label="Fecha de nacimiento"
                      type="date"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-select
                      v-model="sexoClienteTaller"
                      :items="sexos"
                      label="Sexo"
                    ></v-select>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      autocomplete="off"
                      v-model="emailClienteTaller"
                      label="Email *"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs2>
                    <v-select
                      v-model="conoceClienteTaller"
                      :items="conocePorItems"
                      label="¿Cómo nos conoció?"
                    ></v-select>
                  </v-flex>
                  <v-flex xs3>
                    <v-select
                      v-model="interesesClienteTaller"
                      :items="this.$store.state.interestedIn"
                      item-value="id"
                      item-text="nombre"
                      label="¿En qué estás interesado/a?"
                      multiple
                    ></v-select>
                  </v-flex>
                  <v-flex xs2>
                    <v-select
                      v-model="tipoClienteTaller"
                      :items="this.$store.state.typeClient"
                      item-value="nombre"
                      item-text="nombre"
                      label="Tipo de cliente"
                    ></v-select>
                  </v-flex>
                  <v-flex xs2>
                    <v-select
                      v-model="orientacionClienteTaller"
                      :items="orientacionItems"
                      label="Orientación"
                    ></v-select>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      autocomplete="off"
                      v-model="telefonoClienteTaller"
                      type="tel"
                      label="Teléfono"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs2>
                    <v-select
                      v-model="centroClienteTaller"
                      :items="this.$store.state.allCenters"
                      item-value="id"
                      item-text="nombre"
                      label="Centro *"
                    ></v-select>
                  </v-flex>
                  <!-- <v-flex xs2>
                    <v-select
                      v-model="modoClienteTaller"
                      :items="modos"
                      label="Modo"
                    ></v-select>
                  </v-flex> -->
                  <v-spacer></v-spacer>
                  <v-flex xs2>
                    <v-checkbox
                      v-model="lopdClienteTaller"
                      label="Consentimiento Newsletter"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs2>
                    <v-checkbox
                      v-model="fotosClienteTaller"
                      label="Consentimiento para fotos"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12>
                    <v-textarea
                      v-model="notasClienteTaller"
                      label="Notas"
                      counter
                      maxlength="500"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs4>
                    <v-select
                      v-model="pagoClienteTaller"
                      label="Tipo de pago"
                      :items="[
                        'Efectivo',
                        'Tarjeta',
                        'Tarjeta regalo',
                        'Transferencia',
                        'Paypal',
                        'Pendiente de pago',
                        'Invitación',
                        'Sin coste',
                      ]"
                      @change="invitacion('pagoClienteTaller')"
                    ></v-select>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      v-if="pagoClienteTaller == 'Tarjeta regalo'"
                      label="Código"
                      v-model="codigoClienteTaller"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs3>
                    <v-text-field
                      label="Precio"
                      v-model="precioClienteTaller"
                      type="number"
                      readonly
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      label="Descuento %"
                      v-model="pdescClienteTaller"
                      type="number"
                      min="0"
                      @change="calculaPrecioTaller('clienteTaller')"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      label="Descuento €"
                      v-model="descClienteTaller"
                      type="number"
                      min="0"
                      @change="calculaPrecioTaller('clienteTaller')"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      label="Total"
                      v-model="totalClienteTaller"
                      type="number"
                      min="0"
                      @change="calculaPrecioTaller('clienteTaller')"
                      readonly
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </div>
            <div v-else-if="show == 'clientTaller'">
              <v-form ref="formclientTaller">
                <v-layout>
                  <v-flex xs4>
                    <v-autocomplete
                      v-model="selectClientTaller"
                      :items="this.$store.state.clients"
                      :item-text="
                        (item) =>
                          `${item.apellidosRA.toUpperCase()}, ${item.nombreRA.toUpperCase()} | ${
                            item.email
                          } | ${item.telefono}`
                      "
                      item-value="id"
                      flat
                      hide-no-data
                      label="Buscar cliente"
                      prepend-inner-icon="mdi-account-search"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs3>
                    <v-select
                      v-model="selectPagoTaller"
                      label="Tipo de pago"
                      :items="[
                        'Cartera',
                        'Efectivo',
                        'Tarjeta',
                        'Tarjeta regalo',
                        'Transferencia',
                        'Paypal',
                        'Pendiente de pago',
                        'Invitación',
                        'Sin coste',
                      ]"
                      @change="invitacion('selectPagoTaller')"
                    ></v-select>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      v-if="selectPagoTaller == 'Tarjeta regalo'"
                      label="Código"
                      v-model="codigoTaller"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs3>
                    <v-text-field
                      label="Precio"
                      v-model="precioClienteTaller"
                      type="number"
                      readonly
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      label="Descuento %"
                      v-model="pdescClienteTaller"
                      type="number"
                      min="0"
                      @change="calculaPrecioTaller('clienteTaller')"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      label="Descuento €"
                      v-model="descClienteTaller"
                      type="number"
                      min="0"
                      @change="calculaPrecioTaller('clienteTaller')"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      label="Total"
                      v-model="totalClienteTaller"
                      type="number"
                      min="0"
                      @change="calculaPrecioTaller('clienteTaller')"
                      readonly
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </div>
            <div v-else-if="show == 'deleteClientTaller'">
              <v-form ref="formDeleteClientTaller">
                <v-layout>
                  <v-flex xs6>
                    <v-autocomplete
                      v-model="selectDeleteClientTaller"
                      :items="clientsTaller"
                      :item-text="
                        (item) =>
                          `${item.apellidos.toUpperCase()}, ${item.nombre.toUpperCase()} | ${
                            item.email
                          } | ${item.telefono}`
                      "
                      item-value="id"
                      flat
                      hide-no-data
                      label="Buscar cliente"
                      prepend-inner-icon="mdi-account-search"
                    ></v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-form>
            </div>
            <div v-else-if="show == 'deleteAppointment'">
              <v-form ref="formDeleteAppointment">
                <span>¿Estás seguro de querer eliminar esta cita?</span>
                <v-layout>
                  <v-flex xs2>
                    <v-select
                      v-model="reasonDeleteAppointment"
                      placeholder="Razón de borrado"
                      :items="['Cancelada', 'No asiste']"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-form>
            </div>
            <div v-else-if="show == 'cartera'">
              <v-form ref="formCartera">
                <v-layout>
                  <v-flex xs3>
                    <h3>Añadir a la cartera virtual</h3>
                    <v-text-field
                      label="Código asociado a la compra"
                      v-model="buyCodeWallet"
                    >
                    </v-text-field>
                    <v-text-field
                      label="Importe asociado a la compra"
                      v-model="buyEuroWallet"
                    >
                    </v-text-field>
                    <v-btn
                        v-on:click="newBuyWallet"
                        text
                        class="botonLHS"
                      >
                        <v-icon>mdi-content-save</v-icon> Guardar
                      </v-btn>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs6>
                    <h3>Movimientos de la cartera virtual</h3>
                    <v-layout>
                      <v-flex xs2>
                        <v-menu
                          v-model="menuWalletDesde"
                          :close-on-content-click="true"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              autocomplete="off"
                              v-model="dateFormattedWalletDesde"
                              label="Desde"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            @change="
                              changeDateFormatted('walletDesde');
                              loadWallet();
                            "
                            locale="es"
                            :first-day-of-week="1"
                            v-model="walletDesde"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs2>
                        <v-menu
                          v-model="menuWalletHasta"
                          :close-on-content-click="true"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              autocomplete="off"
                              v-model="dateFormattedWalletHasta"
                              label="Hasta"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            @change="
                              changeDateFormatted('walletHasta');
                              loadWallet();
                            "
                            locale="es"
                            :first-day-of-week="1"
                            v-model="walletHasta"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>
                    </v-layout>
                    <v-timeline dense>
                      <v-timeline-item
                        v-for="(movement, i) in walletMovement"
                        :key="i"
                        color="tertiary"
                        small
                      >
                        <template>
                          <span v-text="movement.date"></span>
                          <v-btn
                            icon
                            @click="editMovement(movement)"
                            ><v-icon>mdi-pencil</v-icon></v-btn
                          >
                          <v-btn
                            icon
                            @click="deleteMovement(movement)"
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                        </template>
                        <h4>
                          {{ movement.text }}
                        </h4>
                      </v-timeline-item>
                    </v-timeline>
                  </v-flex>
                </v-layout>
              </v-form>
            </div>
            <div v-else-if="show == 'thanks'">
              <v-form ref="formThanksTaller">
                <v-layout>
                  <v-flex xs2>
                    <v-select
                      v-model="modeThanks"
                      :items="['Email', 'SMS', 'Whatsapp']"
                      label="Método de envío"
                      @change="modeThanksTaller"
                    ></v-select>
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      v-model="selectThanksClientTaller"
                      :items="clientsThanks"
                      :item-text="
                        (item) =>
                          `${item.apellidos.toUpperCase()}, ${item.nombre.toUpperCase()} | ${
                            item.email
                          } | ${item.telefono}`
                      "
                      :item-value="dataThanks"
                      multiple
                      label="Selecciona a aquellos que quieres agradecer su asistencia"
                      prepend-inner-icon="mdi-account-check"
                    ></v-select>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs3>
                    <v-text-field
                      v-model="asunto"
                      label="Asunto del mensaje"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12>
                    <v-text-field
                      v-model="agradecimiento"
                      label="Mensaje a enviar"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </div>
            <div v-else-if="show == 'preFormatted'">
              <pre v-html="text"></pre>
            </div>
            <div v-else>{{ text }}</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="show != 'SiNo'" color="red" text @click.native="cancel"
              >Cancelar</v-btn
            >
            <v-btn
              v-if="show != 'SiNo'"
              color="green"
              text
              @click.native="agree"
              >Aceptar</v-btn
            >
            <v-btn v-if="show == 'SiNo'" color="red" text @click.native="cancel"
              >No</v-btn
            >
            <v-btn
              v-if="show == 'SiNo'"
              color="green"
              text
              @click.native="agree"
              >Sí</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <showMessage ref="snackbar" />
  </div>
</template>
<script>
import { BASE_URL } from "../components/constants.js";
import * as basics from "../components/basics.js";
import showMessage from "../components/Messages.vue";
import genericReload from "../components/genericReload";
export default {
  data() {
    const nuevoBonoDefault = Object.freeze({
      fecha: "",
      centro: "",
      profesional: "",
      tipo: "",
      precio: 0,
      pdesc: 0,
      desc: 0,
      total: 0,
      pagoSimple: true,
      pago: "",
      codigo: "",
      cartera: 0,
      efectivo: 0,
      tarjeta: 0,
      tarjetaRegalo: 0,
      transferencia: 0,
      paypal: 0,
      pendiente: 0,
      invitacion: 0
    });
    const editEventLogDefault = Object.freeze({
      fecha: "",
      compra: "",
      precio: 0,
      pago: "",
      codigo: "",
      profesional: "",
      comentario: "",
    });
    const nuevoTallerDefault = Object.freeze({
      fecha: "",
      centro: "",
      profesional: "",
      tipo: "",
      precio: 0,
      pdesc: 0,
      desc: 0,
      total: 0,
      pagoSimple: true,
      pago: "",
      codigo: "",
      cartera: 0,
      efectivo: 0,
      tarjeta: 0,
      tarjetaRegalo: 0,
      transferencia: 0,
      paypal: 0,
      pendiente: 0,
      invitacion: 0
    });
    const nuevoPrecioDefault = Object.freeze({
      nombre: "",
      precio: 0,
      nutricion: 0,
      psicologia: 0,
      fisioterapia: 0,
      entrenamiento: 0,
    });
    const sesionIndividualDefault = Object.freeze({
      fecha: "",
      centro: "",
      profesional: "",
      precio: 0,
      pdesc: 0,
      desc: 0,
      total: 0,
    });

    //Pesos
    const registroDefault = Object.freeze({
      fecha: "",
      altura: 0,
      peso: 0,
      imc: 0,
      metabolismobasal: 0,
      // tipocuerpo: 0,
      masagrasa: 0,
      pmasagrasa: 0,
      masalibregrasa: 0,
      pmasalibregrasa: 0,
      aguacorporaltotal: 0,
      pact: 0,
      comentario: "",
    });
    return {
      dialog: false,
      title: "",
      text: "",
      show: 0,
      resolve: null,
      reject: null,

      stringPago: "Simple",
      valoraciones: [],
      reasonDeleteAppointment: "",
      menuDate: "",
      menuDate2: "",
      menuDateDesde: "",
      menuDateHasta: "",
      date: "",
      dateFormatted: "",
      dateDesde: "",
      dateFormattedDesde: "",
      dateHasta: "",
      dateFormattedHasta: "",
      dateRecurrencyFormatted: "",
      dateRecurrency: "",
      registroDateFormatted: "",
      nuevoBonoDateFormatted: "",
      editEventLogDateFormatted: "",
      nuevoTallerDateFormatted: "",
      sesionIndividualDateFormatted: "",
      consumirSesionDateFormatted: "",
      consumirSesionCompartidaDateFormatted: "",
      fechaConsumirSesion: "",
      fechaConsumirSesionCompartida: "",
      menuTime: false,
      time: "",
      menuTimeEnd: false,
      timeEnd: "",
      remember: true,
      showClient: "select",
      patient: null,
      patientName: '',
      patientSurname: '',
      patientPhone: '',
      patientEmail: '',
      professional: null,
      alert: null,
      center: null,
      specialty: null,
      mode: null,
      recurrency: null,
      professionals: this.$store.state.professionals,
      clients: JSON.parse(JSON.stringify(this.$store.state.clients)),
      centers: this.$store.state.centers,
      itemsCenter: this.$store.state.centers,
      specialties: this.$store.state.specialties,
      modes: ["Presencial", "Online"],
      recurrencyItems: [
        "No recurrente",
        "Cada semana",
        "Cada dos semanas",
        "Cada tres semanas",
        "Cada cuatro semanas",
      ],
      pass: [],
      workshops: [],
      type: "",
      clienteSesion: "",
      tipoSesion: "",
      notaSesion: "",
      types: ["Nutrición", "Psicología", "Fisioterapia", "Entrenamiento"],
      tiposCuerpo: ["Pequeño", "Normal", "Grande"],
      nombreNewDoc: "",
      permisosNewDoc: "",
      permisos: [
        { value: "admin", name: "Administrador" },
        { value: "coord", name: "Coordinador" },
        { value: "nutricionista", name: "Nutricionista" },
        { value: "psicologo", name: "Psicólogo" },
        { value: "fisioterapeuta", name: "Fisioterapeuta" },
        { value: "entrenador", name: "Entrenador" },
      ],
      fileUploaded: undefined,
      fileUploadedCSV: undefined,
      switchCreaCliente: false,
      data: null,
      msgValora: null,
      nutricionEdita: 0,
      psicologiaEdita: 0,
      fisioterapiaEdita: 0,
      entrenamientoEdita: 0,
      nombreClienteTaller: "",
      apellidosClienteTaller: "",
      emailClienteTaller: "",
      telefonoClienteTaller: "",
      fnacClienteTaller: "",
      sexoClienteTaller: "",
      conoceClienteTaller: "",
      interesesClienteTaller: [],
      tipoClienteTaller: "",
      orientacionClienteTaller: "",
      centroClienteTaller: "",
      modoClienteTaller: "Dual",
      lopdClienteTaller: "",
      fotosClienteTaller: "",
      notasClienteTaller: "",
      pagoClienteTaller: "",
      codigoClienteTaller: "",
      sexos: ["Femenino", "Masculino"],
      modos: ["Presencial", "Online", "Dual"],
      conocePorItems: [
        "Recomendado / Derivado",
        "Internet",
        "Pasé por la puerta",
        "Conocido / Familiar",
      ],
      interesesItems: this.$store.state.interestedIn,
      tipoItems: this.$store.state.typeClient,
      orientacionItems: ["Omnívora", "Vegana", "Vegetariana"],
      selectPagoTaller: null,
      codigoTaller: "",
      selectClientTaller: null,
      selectDeleteClientTaller: null,
      clientsTaller: [],
      precioClienteTaller: 0,
      pdescClienteTaller: 0,
      descClienteTaller: 0,
      totalClienteTaller: 0,
      clientsThanks: [],
      selectThanksClientTaller: null,
      agradecimiento: "",
      asunto: "",
      modeThanks: "Email",
      dataThanks: "email",
      editingWallet: null,
      buyCodeWallet: "",
      buyEuroWallet: "",
      walletMovement: [],
      clientWallet: null,
      menuWalletDesde: null,
      menuWalletHasta: null,
      dateFormattedWalletDesde: null,
      dateFormattedWalletHasta: null,
      walletDesde: null,
      walletHasta: null,
      fullDay: false,
      titleBlock: "",
      textBlock: "",

      nuevoBono: Object.assign({}, nuevoBonoDefault),
      editEventLog: Object.assign({}, editEventLogDefault),
      precio: Object.assign({}, nuevoPrecioDefault),
      nuevoTaller: Object.assign({}, nuevoTallerDefault),
      sesionIndividual: Object.assign({}, sesionIndividualDefault),
      registro: Object.assign({}, registroDefault),
    };
  },
  components: {
    showMessage,
  },
  async mounted() {
    this.$refs.formPesos?.reset();
    this.$refs.formSesionIndividual?.reset();
    this.$refs.formNuevoTaller?.reset();
    this.$refs.formNuevoBono?.reset();
    this.$refs.formEditEventLog?.reset();
    this.$refs.formSesionNewDoc?.reset();
    this.$refs.formSesionCargaCSV?.reset();
    this.$refs.formCitas?.reset();
    this.$refs.formPrecio?.reset();
    this.$refs.formNewClientTaller?.reset();
    this.$refs.formclientTaller?.reset();
    this.$refs.formDeleteClientTaller?.reset();

    await this.getMessages();
  },
  methods: {
    setNew(title, text, show = 0, index = -1, data) {
      this.dialog = true;
      this.title = title;
      this.text = text;
      this.show = show;
      this.menuDate = "";
      this.date = "";
      this.dateFormatted = "";
      this.registroDateFormatted = "";
      this.nuevoBonoDateFormatted = "";
      this.editEventLogDateFormatted = "";
      this.nuevoTallerDateFormatted = "";
      this.sesionIndividualDateFormatted = "";
      this.notes = "";
      this.remember = true;
      this.data = data;
      this.nuevoBono.centro = this.$store.state.idCenter;
      this.nuevoTaller.centro = this.$store.state.idCenter;
      this.sesionIndividual.centro = this.$store.state.idCenter;
      this.precioClienteTaller = 0;
      this.pdescClienteTaller = 0;
      this.descClienteTaller = 0;
      this.totalClienteTaller = 0;

      if (index != -1 && show == "pesos") {
        this.cargaPesoEditar(data);
      }
      if (index != -1 && show == "editEventLog") {
        this.cargaEventoEditar(data);
      }
      if (index == -1 && show == "pesos") {
        this.cargaPesoAnterior(data);
      }
      if (index != -1 && show == "citas") {
        this.cargaCitaEditar(data);
      }
      if (index == -1 && show == "citas") {
        this.patient = data;
        this.loadLastAppointment();
      }
      if (index != -1 && show == "precio") {
        this.cargaPrecio(data);
      }
      if (show == "edicionSesiones") {
        this.cargaEdicionSesiones(data);
      }
      if (show == "bloqueos") {
        this.dateDesde = basics.getDate();
        this.dateFormattedDesde = basics.formatDate(basics.getDate(), "dd/mm/yyyy");
        this.dateHasta = basics.getDate();
        this.dateFormattedHasta = basics.formatDate(basics.getDate(), "dd/mm/yyyy");
        this.professional = this.$store.state.idUser;
      }
      if (show == "nuevoBono") {
        this.nuevoBono.pagoSimple = true,
        this.nuevoBono.pago = "";
        this.nuevoBono.codigo = "";
        this.nuevoBono.cartera = 0;
        this.nuevoBono.efectivo = 0;
        this.nuevoBono.tarjeta = 0;
        this.nuevoBono.tarjetaRegalo = 0;
        this.nuevoBono.transferencia = 0;
        this.nuevoBono.paypal = 0;
        this.nuevoBono.pendiente = 0;
        this.nuevoBono.invitacion = 0;
        this.modoPagoToString('nuevoBono');
        this.getPass();
      }
      if (show == "cartera") {
        let d = new Date();
        this.clientWallet = data;
        this.walletDesde = d.getFullYear() + "-01-01";
        this.dateFormattedWalletDesde = basics.formatDate(
          d.getFullYear() + "-01-01",
          "dd/mm/yyyy"
        );
        this.walletHasta = basics.getDate();
        this.dateFormattedWalletHasta = basics.formatDate(basics.getDate(), "dd/mm/yyyy");
        this.loadWallet();
      }
      if (show == "deleteClientTaller") {
        for (let i = 0; i < data.length; i++) {
          let element = this.$store.state.clients.find(
            (client) => client.id === data[i]
          );
          this.clientsTaller.push({
            id: element.id,
            nombre: element.nombre,
            apellidos: element.apellidos,
            email: element.email,
            telefono: element.telefono,
          });
        }
      }
      if (show == "clientTaller" || show == "newClientTaller") {
        this.precioClienteTaller = data;
        this.calculaPrecioTaller("clienteTaller");
      }
      if (show == "nuevoTaller") {
        this.nuevoTaller.pagoSimple = true,
        this.nuevoTaller.pago = "";
        this.nuevoTaller.codigo = "";
        this.nuevoTaller.cartera = 0;
        this.nuevoTaller.efectivo = 0;
        this.nuevoTaller.tarjeta = 0;
        this.nuevoTaller.tarjetaRegalo = 0;
        this.nuevoTaller.transferencia = 0;
        this.nuevoTaller.paypal = 0;
        this.nuevoTaller.pendiente = 0;
        this.nuevoTaller.invitacion = 0;
        this.modoPagoToString('nuevoTaller');
        const fecha = new Date();
        this.nuevoTaller.fecha =
          fecha.getFullYear() +
          "-" +
          (fecha.getMonth() + 1) +
          "-" +
          fecha.getDate();
        this.nuevoTallerDateFormatted = basics.formatDate(
          fecha.getFullYear() +
            "-" +
            (fecha.getMonth() + 1) +
            "-" +
            fecha.getDate(),
          "dd/mm/yyyy"
        );
        this.getWorkshops();
      }
      if (show == "thanks") {
        for (let i = 0; i < data.length; i++) {
          let element = this.$store.state.clients.find(
            (client) => client.id === data[i]
          );
          this.clientsThanks.push({
            id: element.id,
            nombre: element.nombre,
            apellidos: element.apellidos,
            email: element.email,
            telefono: element.telefono,
          });
        }
      }

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      let close = true;
      switch (this.show) {
        case "pesos":
          this.resolve({
            response: true,
            fecha: this.registroDateFormatted,
            altura: this.registro.altura,
            peso: this.registro.peso,
            imc: this.registro.imc,
            metabolismobasal: this.registro.metabolismobasal,
            // tipocuerpo: this.registro.tipocuerpo,
            masagrasa: this.registro.masagrasa,
            pmasagrasa: this.registro.pmasagrasa,
            masalibregrasa: this.registro.masalibregrasa,
            pmasalibregrasa: this.registro.pmasalibregrasa,
            aguacorporaltotal: this.registro.aguacorporaltotal,
            pact: this.registro.pact,
            comentario: this.registro.comentario,
            editando: true,
          });
          this.$refs.formPesos.reset();
          break;

        case "sesionIndividual":
          this.resolve({
            response: true,
            fecha: this.sesionIndividual.fecha,
            profesional: this.sesionIndividual.profesional,
            centro: this.sesionIndividual.centro,
            precio: this.sesionIndividual.precio,
            pdesc: this.sesionIndividual.pdesc,
            desc: this.sesionIndividual.desc,
          });
          this.$refs.formSesionIndividual.reset();
          break;

        case "nuevoTaller":
          if(!this.nuevoTaller.pagoSimple){
            if(
              (parseFloat(this.nuevoTaller.cartera) + 
              parseFloat(this.nuevoTaller.efectivo) +
              parseFloat(this.nuevoTaller.tarjeta) +
              parseFloat(this.nuevoTaller.tarjetaRegalo) +
              parseFloat(this.nuevoTaller.transferencia) +
              parseFloat(this.nuevoTaller.paypal) +
              parseFloat(this.nuevoTaller.pendiente) +
              parseFloat(this.nuevoTaller.invitacion)) != 
              parseFloat(this.nuevoTaller.total)
            ){
              close = false;
              this.$refs.snackbar.setNew(true, "ERROR. Importe pagado incorrecto. El dinero aportado no corresponde con el total a pagar.", "Cerrar", "red");
            }
          }
          if(close){
            this.resolve({
              response: true,
              fecha: this.nuevoTaller.fecha,
              profesional: this.nuevoTaller.profesional,
              centro: this.nuevoTaller.centro,
              tipo: this.nuevoTaller.tipo,
              precio: this.nuevoTaller.precio,
              pdesc: this.nuevoTaller.pdesc,
              desc: this.nuevoTaller.desc,
              pago: this.nuevoTaller.pago,
              codigo: this.nuevoTaller.codigo,
              pagoSimple: this.nuevoTaller.pagoSimple,
              cartera: this.nuevoTaller.cartera,
              efectivo: this.nuevoTaller.efectivo,
              tarjeta: this.nuevoTaller.tarjeta,
              tarjetaRegalo: this.nuevoTaller.tarjetaRegalo,
              transferencia: this.nuevoTaller.transferencia,
              paypal: this.nuevoTaller.paypal,
              pendiente: this.nuevoTaller.pendiente,
              invitacion: this.nuevoTaller.invitacion
            });
            this.$refs.formNuevoTaller.reset();
          }
          break;

        case "precio":
          this.resolve({
            response: true,
            nombre: this.precio.nombre,
            precio: this.precio.precio,
            nutricion: this.precio.nutricion,
            psicologia: this.precio.psicologia,
            fisioterapia: this.precio.fisioterapia,
            entrenamiento: this.precio.entrenamiento,
          });
          this.$refs.formPrecio.reset();
          break;

        case "deleteAppointment":
          this.resolve({
            response: true,
            reason: this.reasonDeleteAppointment,
          });
          break;

        case "nuevoBono":
          if(!this.nuevoBono.pagoSimple){
            if(
              (parseFloat(this.nuevoBono.cartera) + 
              parseFloat(this.nuevoBono.efectivo) +
              parseFloat(this.nuevoBono.tarjeta) +
              parseFloat(this.nuevoBono.tarjetaRegalo) +
              parseFloat(this.nuevoBono.transferencia) +
              parseFloat(this.nuevoBono.paypal) +
              parseFloat(this.nuevoBono.pendiente) +
              parseFloat(this.nuevoBono.invitacion)) != 
              parseFloat(this.nuevoBono.total)
            ){
              close = false;
              this.$refs.snackbar.setNew(true, "ERROR. Importe pagado incorrecto. El dinero aportado no corresponde con el total a pagar.", "Cerrar", "red");
            }
          }
          if(close){
            this.resolve({
              response: true,
              fecha: this.nuevoBono.fecha,
              profesional: this.nuevoBono.profesional,
              centro: this.nuevoBono.centro,
              tipo: this.nuevoBono.tipo,
              precio: this.nuevoBono.precio,
              pdesc: this.nuevoBono.pdesc,
              desc: this.nuevoBono.desc,
              pago: this.nuevoBono.pago,
              codigo: this.nuevoBono.codigo,
              pagoSimple: this.nuevoBono.pagoSimple,
              cartera: this.nuevoBono.cartera,
              efectivo: this.nuevoBono.efectivo,
              tarjeta: this.nuevoBono.tarjeta,
              tarjetaRegalo: this.nuevoBono.tarjetaRegalo,
              transferencia: this.nuevoBono.transferencia,
              paypal: this.nuevoBono.paypal,
              pendiente: this.nuevoBono.pendiente,
              invitacion: this.nuevoBono.invitacion
            });
            this.$refs.formNuevoBono.reset();
          }
          break;

        case "editEventLog":
          this.resolve({
            response: true,
            fecha: this.editEventLog.fecha,
            precio: this.editEventLog.precio,
            pago: this.editEventLog.pago,
            codigo: this.editEventLog.codigo,
            profesional: this.editEventLog.profesional,
            comentario: this.editEventLog.comentario,
          });
          this.$refs.formEditEventLog.reset();
          break;

        case "newDoc":
          this.resolve({
            response: true,
            file: this.fileUploaded,
            name: this.nombreNewDoc,
            permissions: this.permisosNewDoc,
          });
          this.$refs.formSesionNewDoc.reset();
          break;

        case "cargaCSV":
          this.resolve({
            response: true,
            file: this.fileUploadedCSV,
            crea: this.switchCreaCliente,
          });
          this.$refs.formSesionCargaCSV.reset();
          break;

        case "consumirSesion":
          this.resolve({
            response: true,
            tipoSesion: this.tipoSesion,
            notaSesion: this.notaSesion,
            fechaSesion: this.fechaConsumirSesion,
          });
          this.tipoSesion = "";
          this.notaSesion = "";
          this.fechaConsumirSesion = "";
          this.consumirSesionDateFormatted = "";
          break;

        case "consumirSesionCompartida":
          this.resolve({
            response: true,
            clienteSesion: this.clienteSesion,
            tipoSesion: this.tipoSesion,
            notaSesion: this.notaSesion,
            fechaSesion: this.fechaConsumirSesionCompartida,
          });
          this.clienteSesion = "";
          this.tipoSesion = "";
          this.notaSesion = "";
          this.fechaConsumirSesionCompartida = "";
          this.consumirSesionCompartidaDateFormatted = "";
          break;

        case "bloqueos":
          if (
            this.dateDesde != "" &&
            this.time != "" &&
            this.dateHasta != "" &&
            this.timeEnd != ""
          ) {
            this.resolve({
              response: true,
              date: this.dateDesde.replace(/[^0-9\/-]/g, ''),
              time: this.time.replace(/[^0-9:]/g, ''),
              dateEnd: this.dateHasta.replace(/[^0-9\/-]/g, ''),
              timeEnd: this.timeEnd.replace(/[^0-9:]/g, ''),
              professional: this.professional,
              recurrency: this.recurrency,
              dateRecurrency: this.dateRecurrency,
              titleBlock: this.titleBlock,
              textBlock: this.textBlock,
            });
            this.$refs.formBloqueos.reset();
          } else {
            this.$refs.snackbar.setNew(
              true,
              "Faltan datos importantes que completar antes de cerrar el bloqueo.",
              "Cerrar",
              "red"
            );
          }
          break;
        
        case "citas":
          if (
            this.date != "" &&
            this.time != "" &&
            this.timeEnd != "" &&
            (this.patient != null || 
            (this.patientName != "" && this.patientSurname != "" && this.patientEmail != "" && this.patientPhone != ""))
          ) {
            this.resolve({
              response: true,
              date: this.date.replace(/[^0-9\/-]/g, ''),
              time: this.time.replace(/[^0-9:]/g, ''),
              timeEnd: this.timeEnd.replace(/[^0-9:]/g, ''),
              patient: this.patient,
              professional: this.professional,
              alert: this.alert,
              center: this.center,
              specialty: this.specialty,
              mode: this.mode,
              remember: this.remember,
              recurrency: this.recurrency,
              dateRecurrency: this.dateRecurrency,
              remember: this.remember,
              patientName: this.patientName,
              patientSurname: this.patientSurname,
              patientPhone: this.patientPhone,
              patientEmail: this.patientEmail,
              show: this.showClient,
            });
            this.$refs.formCitas.reset();
          } else {
            this.$refs.snackbar.setNew(
              true,
              "Faltan datos importantes que completar antes de cerrar la cita.",
              "Cerrar",
              "red"
            );
          }
          break;

        case "manualLoad":
          let values = [];
          let line = 0;
          let value = "";
          let idClient = 0;
          let divide = "";
          for (let i = 0; i < this.data.length; i++) {
            line = this.data[i];
            value = document.getElementById(this.data[i]).value;
            let name, surname;
            if (value.indexOf(",") > -1) {
              divide = value.split(", ");
              name = divide[1];
              surname = divide[0];
            } else {
              name = value;
              surname = "";
            }
            values[line] = this.$store.getters.clientByName(
              name.toUpperCase(),
              surname.toUpperCase()
            ).id;
          }
          this.resolve({
            response: true,
            values: values,
          });
          this.data = null;
          break;

        case "valora":
          this.resolve({
            response: true,
            msgValora: this.msgValora,
          });
          this.msgValora = null;
          break;

        case "edicionSesiones":
          this.resolve({
            response: true,
            nutricion: this.nutricionEdita,
            psicologia: this.psicologiaEdita,
            fisioterapia: this.fisioterapiaEdita,
            entrenamiento: this.entrenamientoEdita,
          });
          this.nutricionEdita = 0;
          this.psicologiaEdita = 0;
          this.fisioterapiaEdita = 0;
          this.entrenamientoEdita = 0;
          break;

        case "newClientTaller":
          if(!this.pagoClienteTaller){
            close = false;
            this.$refs.snackbar.setNew(true, "ERROR. Debes indicar un método de pago", "Cerrar", "red");
          }
          if(close){
            this.resolve({
              response: true,
              nombre: this.nombreClienteTaller,
              apellidos: this.apellidosClienteTaller,
              telefono: this.telefonoClienteTaller,
              email: this.emailClienteTaller,
              fnac: this.fnacClienteTaller,
              sexo: this.sexoClienteTaller,
              conoce: this.conoceClienteTaller,
              intereses: this.interesesClienteTaller,
              tipo: this.tipoClienteTaller,
              orientacion: this.orientacionClienteTaller,
              centro: this.centroClienteTaller,
              modo: this.modoClienteTaller,
              lopd: this.lopdClienteTaller,
              fotos: this.fotosClienteTaller,
              notas: this.notasClienteTaller,
              pago: this.pagoClienteTaller,
              codigo: this.codigoClienteTaller,
              descuento: this.descClienteTaller,
              pdescuento: this.pdescClienteTaller,
            });
            this.$refs.formNewClientTaller.reset();
          }
          break;

        case "clientTaller":
          if(!this.selectPagoTaller){
            close = false;
            this.$refs.snackbar.setNew(true, "ERROR. Debes indicar un método de pago", "Cerrar", "red");
          }
          if(close){
            this.resolve({
              response: true,
              id: this.selectClientTaller,
              pago: this.selectPagoTaller,
              codigo: this.codigoTaller,
              descuento: this.descClienteTaller,
              pdescuento: this.pdescClienteTaller,
            });
            this.$refs.formclientTaller.reset();
          }
          break;

        case "deleteClientTaller":
          this.resolve({
            response: true,
            id: this.selectDeleteClientTaller,
          });
          this.$refs.formDeleteClientTaller.reset();
          break;

        case "thanks":
          this.resolve({
            response: true,
            clients: this.selectThanksClientTaller,
            message: this.agradecimiento,
            subject: this.asunto,
            mode: this.modeThanks,
          });
          this.$refs.formThanksTaller.reset();
          // this.modeThanks = "Email";
          // this.dataThanks = "email";
          // this.modeThanksTaller();
          break;

        default:
          this.resolve({ response: true, date: this.date, notes: this.notes });
          break;
      }
      if(close) { this.dialog = false; }
    },
    cancel() {
      this.$refs.formPesos?.reset();
      this.$refs.formSesionIndividual?.reset();
      this.$refs.formNuevoTaller?.reset();
      this.$refs.formNuevoBono?.reset();
      this.$refs.formEditEventLog?.reset();
      this.$refs.formSesionNewDoc?.reset();
      this.$refs.formSesionCargaCSV?.reset();
      this.$refs.formCitas?.reset();
      this.$refs.formNewClientTaller?.reset();
      this.$refs.formclientTaller?.reset();
      this.$refs.formDeleteClientTaller?.reset();
      this.$refs.formThanksTaller?.reset();
      this.resolve(false);
      this.dialog = false;
    },
    changeDateFormatted(field) {
      switch (field) {
        case "date":
          this.dateFormatted = basics.formatDate(this.date, "dd/mm/yyyy");
          break;
        case "dateDesde":
          this.dateFormattedDesde = basics.formatDate(this.dateDesde, "dd/mm/yyyy");
          break;
        case "dateHasta":
          this.dateFormattedHasta = basics.formatDate(this.dateHasta, "dd/mm/yyyy");
          break;
        case "dateRecurrency":
          this.dateRecurrencyFormatted = basics.formatDate(
            this.dateRecurrency,
            "dd/mm/yyyy"
          );
          break;
        case "registro":
          this.registroDateFormatted = basics.formatDate(
            this.registro.fecha,
            "dd/mm/yyyy"
          );
          break;
        case "nuevoBono":
          this.nuevoBonoDateFormatted = basics.formatDate(
            this.nuevoBono.fecha,
            "dd/mm/yyyy"
          );
          break;
        case "editEventLog":
          this.editEventLogDateFormatted = basics.formatDate(
            this.editEventLog.fecha,
            "dd/mm/yyyy"
          );
          break;
        case "nuevoTaller":
          this.nuevoTallerDateFormatted = basics.formatDate(
            this.nuevoTaller.fecha,
            "dd/mm/yyyy"
          );
          break;
        case "sesionIndividual":
          this.sesionIndividualDateFormatted = basics.formatDate(
            this.sesionIndividual.fecha,
            "dd/mm/yyyy"
          );
          break;
        case "consumirSesion":
          this.consumirSesionDateFormatted = basics.formatDate(
            this.fechaConsumirSesion,
            "dd/mm/yyyy"
          );
          break;
        case "consumirSesionCompartida":
          this.consumirSesionCompartidaDateFormatted = basics.formatDate(
            this.fechaConsumirSesionCompartida,
            "dd/mm/yyyy"
          );
          break;
        case "walletDesde":
          this.dateFormattedWalletDesde = basics.formatDate(
            this.walletDesde,
            "dd/mm/yyyy"
          );
          break;
        case "walletHasta":
          this.dateFormattedWalletHasta = basics.formatDate(
            this.walletHasta,
            "dd/mm/yyyy"
          );
          break;
      }
    },
    async cargaPesoAnterior(data) {
      const response = await genericReload(
        this.$store.state.auth,
        "api/nutrition/?idCliente=" + data
      );
      if (response.status === 200) {
        const json = await response.json();
        if (json.length) {
          this.registro.altura = json[json.length - 1].altura;
          // this.registro.tipocuerpo = json[json.length - 1].tipo;
        }
      }
    },
    async getMessages() {
      const response = await genericReload(
        this.$store.state.auth,
        "api/message"
      );
      if (response.status === 200) {
        this.valoraciones = await response.json();
        this.valoraciones = this.valoraciones.filter(
          (message) => message.titulo.indexOf("Valoración") > -1
        );
      }
    },
    cargaPesoEditar(data) {
      this.registroDateFormatted = data.fecha;
      this.registro.fecha = basics.formatDate(data.fecha, "yyyy-mm-dd", "/");
      this.registro.altura = data.altura;
      this.registro.peso = data.peso;
      this.registro.imc = data.imc;
      this.registro.metabolismobasal = data.metabolismobasal;
      // this.registro.tipocuerpo = data.tipocuerpo;
      this.registro.masagrasa = data.masagrasa;
      this.registro.pmasagrasa = data.pmasagrasa;
      this.registro.masalibregrasa = data.masalibregrasa;
      this.registro.pmasalibregrasa = data.pmasalibregrasa;
      this.registro.aguacorporaltotal = data.aguacorporaltotal;
      this.registro.pact = data.pact;
      this.registro.comentario = data.comentario;
    },
    calculaPrecio() {
      const precio = parseFloat(this.sesionIndividual.precio);
      const pdesc = parseFloat(this.sesionIndividual.pdesc);
      const desc = parseFloat(this.sesionIndividual.desc);
      this.sesionIndividual.total =
        Math.round((precio - precio * (pdesc / 100) - desc) * 100) / 100;
    },
    calculaPrecioBono() {
      const precio = parseFloat(this.nuevoBono.precio);
      const pdesc = parseFloat(this.nuevoBono.pdesc);
      const desc = parseFloat(this.nuevoBono.desc);
      this.nuevoBono.total =
        Math.round((precio - precio * (pdesc / 100) - desc) * 100) / 100;
    },
    calculaPrecioTaller(form) {
      let precio, pdesc, desc;
      switch (form) {
        case "nuevoTaller":
          precio = parseFloat(this.nuevoTaller.precio);
          pdesc = parseFloat(this.nuevoTaller.pdesc);
          desc = parseFloat(this.nuevoTaller.desc);
          this.nuevoTaller.total =
            Math.round((precio - precio * (pdesc / 100) - desc) * 100) / 100;
          break;
        case "clienteTaller":
          precio = parseFloat(this.precioClienteTaller);
          pdesc = parseFloat(this.pdescClienteTaller);
          desc = parseFloat(this.descClienteTaller);
          this.totalClienteTaller =
            Math.round((precio - precio * (pdesc / 100) - desc) * 100) / 100;
          break;
      }
    },
    getPrice() {
      const passById = this.$store.getters.passById(this.nuevoBono.tipo);
      if (passById) {
        this.nuevoBono.precio = passById.precio;
        this.nuevoBono.desc = 0;
        this.nuevoBono.pdesc = 0;
        this.calculaPrecioBono();
      }
    },
    getPriceWorkshops() {
      const workshopById = this.$store.getters.workshopById(
        this.nuevoTaller.tipo
      );
      if (workshopById) {
        this.nuevoTaller.precio = workshopById.precio;
        this.nuevoTaller.desc = 0;
        this.nuevoTaller.pdesc = 0;
        this.calculaPrecioTaller("nuevoTaller");
      }
    },
    getPass() {
      this.pass = [];
      const passList = this.$store.getters.passByCenter(this.nuevoBono.centro);
      if (passList) {
        for (let i = 0; i < passList.length; i++) {
          this.pass.push({
            id: passList[i].id,
            name: passList[i].nombre,
            price: passList[i].precio,
          });
        }
      }
    },
    getWorkshops() {
      this.workshops = [];
      const workshopsList = this.$store.getters.workshopByCenter(
        this.nuevoTaller.centro
      );
      if (workshopsList) {
        for (let i = 0; i < workshopsList.length; i++) {
          this.workshops.push({
            id: workshopsList[i].id,
            name: workshopsList[i].nombre,
            date: workshopsList[i].fecha,
            price: workshopsList[i].precio,
          });
        }
      }
    },
    copyName() {
      if (this.fileUploaded) {
        this.nombreNewDoc = this.fileUploaded.name;
      }
    },
    timeAuto() {
      if (this.time) {
        let hora = this.time;
        let [horas, minutos] = hora.split(":");
        horas = parseFloat(horas);
        minutos = parseFloat(minutos);

        const horas_Segundos = horas * 3600;
        const minutos_Segundos = (minutos + 20) * 60;
        const segundos = minutos_Segundos + horas_Segundos;

        let hours = Math.floor(segundos / 3600);
        let minutes = Math.floor((segundos % 3600) / 60);

        //Anteponiendo un 0 si son menos de 10
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        this.timeEnd = hours + ":" + minutes;
      }
    },
    cargaEventoEditar(data) {
      this.editEventLog.fecha = basics.formatDate(data.date, "yyyy-mm-dd", "/");
      this.editEventLogDateFormatted = data.date;
      const [compra, precio, pago, comentario] = data.text.split(" | ");
      this.editEventLog.compra = compra;
      this.editEventLog.precio = precio;
      this.editEventLog.pago = pago;
      this.editEventLog.codigo = data.codigo;
      this.editEventLog.comentario = comentario;
      this.editEventLog.profesional = data.profesional;
    },
    cargaCitaEditar(data) {
      if (data.date) {
        this.date = data.date;
        this.dateFormatted = basics.formatDate(this.date, "dd/mm/yyyy");
        this.time = data.time;
        this.timeEnd = data.timeEnd;
        this.patient = data.patient;
        this.professional = data.professional;
        this.center = data.center;
        this.specialty = data.specialty;
        this.mode = data.mode;
        this.remember = data.remember;
      } else {
        this.patient = data[0];
        this.professional = data[1];
        this.center = data[2];
      }
    },
    cargaEdicionSesiones(data) {
      this.nutricionEdita = data[0];
      this.psicologiaEdita = data[1];
      this.fisioterapiaEdita = data[2];
      this.entrenamientoEdita = data[3];
    },
    cargaPrecio(data) {
      this.precio.nombre = data.nombre;
      this.precio.precio = data.precio;
      this.precio.nutricion = data.nutricion;
      this.precio.psicologia = data.psicologia;
      this.precio.fisioterapia = data.fisioterapia;
      this.precio.entrenamiento = data.entrenamiento;
    },
    async loadLastAppointment() {
      const response = await genericReload(
        this.$store.state.auth,
        "api/appointment/?idCliente=" + this.patient
      );
      if (response.status === 200) {
        const json = await response.json();
        if (json.length) {
          this.professional = json[json.length - 1].idProfesional;
          this.center = json[json.length - 1].idCentro;
          this.specialty = json[json.length - 1].tipo;
          this.mode = json[json.length - 1].modo;
        }
      }
    },
    invitacion(form) {
      switch (form) {
        case "nuevoTaller":
          if (this.nuevoTaller.pago == "Invitación") {
            this.nuevoTaller.pdesc = 100;
            this.nuevoTaller.desc = 0;
          } else {
            // this.nuevoTaller.pdesc = 0;
            // this.nuevoTaller.desc = 0;
          }
          this.calculaPrecioTaller("nuevoTaller");
          break;
        case "nuevoBono":
          if (this.nuevoBono.pago == "Invitación") {
            this.nuevoBono.pdesc = 100;
            this.nuevoBono.desc = 0;
          } else {
            // this.nuevoBono.pdesc = 0;
            // this.nuevoBono.desc = 0;
          }
          this.calculaPrecioBono();
          break;
        case "editEventLog":
          if (this.editEventLog.pago == "Invitación") {
            this.editEventLog.precio = 0;
          }
          break;
        case "pagoClienteTaller":
          if (this.pagoClienteTaller == "Invitación") {
            this.pdescClienteTaller = 100;
            this.descClienteTaller = 0;
          } else {
            // this.pdescClienteTaller = 0;
            // this.descClienteTaller = 0;
          }
          this.calculaPrecioTaller("clienteTaller");
          break;
        case "selectPagoTaller":
          if (this.selectPagoTaller == "Invitación") {
            this.pdescClienteTaller = 100;
            this.descClienteTaller = 0;
          } else {
            // this.pdescClienteTaller = 0;
            // this.descClienteTaller = 0;
          }
          this.calculaPrecioTaller("clienteTaller");
          break;
      }
    },
    modeThanksTaller() {
      if (this.modeThanks == "Email") {
        this.dataThanks = "email";
      } else {
        this.dataThanks = "telefono";
      }
      this.selectThanksClientTaller = null;
    },
    loadCenters() {
      if (this.professional == this.$store.state.idUser) {
        this.itemsCenter = this.$store.state.centers;
      } else {
        this.itemsCenter = this.$store.state.allCenters;
      }
    },
    async newBuyWallet(){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };
      let response;
      if(!this.editingWallet){
        const postData = {
          cliente: this.clientWallet,
          codigo: this.buyCodeWallet,
          importe: this.buyEuroWallet,
          tipo: "ingreso",
        };

        response = await fetch(
          `${BASE_URL}/api/wallet`,
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(postData),
          }
        );
      }else{
        const putData = {
          cliente: this.clientWallet,
          codigo: this.buyCodeWallet,
          importe: this.buyEuroWallet,
          tipo: "ingreso",
        };

        response = await fetch(
          `${BASE_URL}/api/wallet/${this.editingWallet}`,
          {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(putData),
          }
        );
      }
      switch (response.status) {
        case 200:
          this.loadWallet();
          this.buyCodeWallet = "";
          this.buyEuroWallet = "";
          this.editingWallet = null;
          this.$refs.snackbar.setNew(true, "Movimiento registrado correctamente", "Ok", "green");
          break;
        default:
          this.$refs.snackbar.setNew(true, "ERROR. Movimiento no registrado", "Cerrar", "red");
          break;
        }
    },
    async loadWallet(){
      this.walletMovement = [];
      const response = await genericReload(
        this.$store.state.auth,
        "api/wallet/?cliente=" +
          this.clientWallet +
          "&fechaInicio=" +
          this.walletDesde +
          "&fechaFin=" +
          this.walletHasta
      );
      if (response.status === 200) {
        const json = await response.json();
        for (let i = 0; i < json.length; i++) {
          if(json[i].tipo == "ingreso"){
            this.walletMovement.push({
              id: json[i].id,
              date: basics.formatDate(json[i].fecha, "dd/mm/yyyy"),
              text: "+"+json[i].importe+"€ | Código: "+json[i].codigo,
            });
          }else{
            this.walletMovement.push({
              id: json[i].id,
              date: basics.formatDate(json[i].fecha, "dd/mm/yyyy"),
              text: "-"+json[i].importe+"€ | "+json[i].codigo,
            });
          }
        }
      }
    },
    async editMovement(m){
      const response = await genericReload(
        this.$store.state.auth,
        "api/wallet/" + m.id
      );
      if (response.status === 200) {
        const json = await response.json();
        if (json.length) {
          this.buyCodeWallet = json[0].codigo;
          this.buyEuroWallet = json[0].importe;
          this.editingWallet = m.id;
        }
      }
    },
    async deleteMovement(m){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };
      let response = await fetch(
        `${BASE_URL}/api/wallet/${m.id}`,
        {
          method: "DELETE",
          headers: headers,
        }
      );
      switch (response.status) {
        case 200:
          this.loadWallet();
          break;
        default:
          this.$refs.snackbar.setNew(true, basics.returnError(response.status), "Cerrar", "red");
          break;
      }
    },
    modoPagoToString(e){
      switch (e) {
        case "nuevoBono":
          (this.nuevoBono.pagoSimple) ? this.stringPago = "Simple" : this.stringPago = "Compuesto";
          break;
        case "nuevoTaller":
          (this.nuevoTaller.pagoSimple) ? this.stringPago = "Simple" : this.stringPago = "Compuesto";
          break;
      }
    },
    fullDayChecked(){
      if(this.fullDay){
        this.time = "08:00";
        this.timeEnd = "21:00";
      }else{
        this.time = null;
        this.timeEnd = null;
      }
    },
    copyDate(){
      this.dateHasta = this.dateDesde
      this.dateFormattedHasta = this.dateFormattedDesde
    }
  },
};
</script>
<style scoped>
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}
.container {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .container {
    width: 60%;
  }
}
</style>
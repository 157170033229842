<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card v-if="recoverShow == false" class="elevation-12">
              <v-toolbar light color="tertiary">
                <v-toolbar-title>Inicio de sesión</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field v-on:keyup.enter="login" id="user" prepend-icon="mdi-account-circle" name="login" label="Usuario" type="text" v-model="post.user"></v-text-field>
                  <v-text-field v-on:keyup.enter="login" id="password" prepend-icon="mdi-lock" name="password" label="Contraseña" :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (value = !value)" :type="value ? 'password' : 'text'" v-model="post.pass"></v-text-field>
                </v-form>
                <p>{{ message }}</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="showRecover" text color="tertiary">Recupera contraseña</v-btn>
                <v-btn @click="login" :loading="loading" color="tertiary">Acceder</v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-if="recoverShow == true" class="elevation-12">
              <v-toolbar dark color="tertiary">
                <v-toolbar-title>Recupera contraseña</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field v-on:keyup.enter="recover" id="recoverUser" prepend-icon="mdi-account-circle" name="recoverUser" label="Usuario" type="text" v-model="putRecover.user"></v-text-field>
                </v-form>
                <p>{{ messageRecover }}</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="showRecover" text color="tertiary">Inicia sesión</v-btn>
                <v-btn @click="recover" color="tertiary">Recuperar</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import {BASE_URL} from '../components/constants.js'
export default {
  name: 'Login',
  data: () => ({
    value: true,
    message: "",
    messageRecover: "",
    recoverShow: false,
    loading: false,
    post: {
      user: "",
      pass: ""
    },
    putRecover: {
      user: ""
    },
  }),
  beforeMount() {
    this.cierraSesion();
  },
  methods: {
    async login() {
      this.loading = true;
      var postData = {
        email: this.post.user,
        contrasena: this.post.pass,
      };
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.$store.state.auth}`,
      };
      const response = await fetch(
        `${BASE_URL}/api/auth/login`,
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(postData),
        }
      );

      switch (response.status) {
        case 200: 
          const json = await response.json();
          this.$store.commit('modifyAuth',json.JWT);
          this.$store.commit('modifyUser',json.id);
          this.message = "";
          await this.$store.dispatch('loadData');
          if(navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i)){
            this.$router.push({name: 'Calendario'});
          }else{
            this.$router.push({name: 'Inicio'});
          }
          this.loading = false;
          break;
        case 400:
          if(this.post.user!="" && this.post.pass!=""){
            this.message = "Error en la aplicación. Consulte a soporte técnico.";
            this.loading = false;
          }else{
            this.message = "Los campos de inicio de sesión son obligatorios";
            this.loading = false;
          }
          break;
        case 401:
          this.message = "Datos de inicio de sesión erróneos";
          this.loading = false;
          break;
        case 500:
          this.message = "Error en el servidor. Consulte a soporte técnico.";
          this.loading = false;
          break;
        default:
          this.message = "No ha sido posible iniciar sesión. Es posible que no tenga buena conexión a Internet.";
          this.loading = false;
          break;
      }
    },
    showRecover(){
      if(this.recoverShow){
        this.recoverShow = false
      }else{
        this.recoverShow = true
      }
    },
    async recover(){
      var putData = {
        email: this.putRecover.user
      };
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };

      const response = await fetch(
        `${BASE_URL}/api/auth/recoverPass`,
        {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(putData),
        }
      );
      switch (response.status) {
        case 202:
          this.messageRecover = "Se ha enviado un email a su correo. Por favor, revíselo.";
          break;
        case 400:
          if(this.putRecover.user!="")
            this.messageRecover = "Error en la aplicación. Consulte a soporte técnico.";
          else
            this.messageRecover = "Introduzca su email para recuperar la contraseña";
          break;
        case 500:
          this.messageRecover = "Error en el servidor. Consulte a soporte técnico.";
          break;
      }
    },
    async checkJWT(){
      var postData = {
        email: this.post.user,
        password: this.post.pass,
      };
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };

      const response = await fetch(
        `${BASE_URL}/api/auth/checkJWT`,
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(postData),
        }
      );
      switch (response.status) {
        case 200:
          return "ok";
        default:
          return response.status;
        }
    },
    cierraSesion(){
      this.$store.commit('modifyAuth','');
      this.$store.commit('modifyUser',0);
      this.$store.commit('modifyNameUser',"");
      this.$store.commit('modifyTypeUser',"");
      // this.$store.commit('modifyActualCenter',0);
      // this.$store.commit('modifyKeepAlive',false);
      this.$store.state.centers = [];
      this.$store.state.allCenters = [];
      this.$store.state.clients = [];
      this.$store.state.workshops = [];
      this.$store.state.professionals = [];
      this.$store.state.messages = [];
      this.$store.state.interestedIn = [];
    }
  }
}
</script>
<style scoped>
main {
  background-image: url("../assets/fondoLogin.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}
</style>

<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-layout align-center justify-center>
          <v-flex xs12 sm10 md8>
            <h1
              style="
                text-align: left;
                font-size: 1.5em;
                float: left;
                padding-right: 2em;
                filter: drop-shadow(2px 4px 8px #fff);
              "
            >
              <span style="color: black"
                >Queremos darte las gracias por<br />haber asistido a nuestro
                taller</span
              ><br /><span style="color: #fff"
                >¿Nos ayudas con una valoración?</span
              >
            </h1>
            <v-img
              class="shrink mr-2"
              contain
              src="@/assets/flecha.png"
              transition="scale-transition"
              width="150"
              style="
                filter: drop-shadow(2px 4px 8px #585858);
                -moz-transform: rotate(-15deg);
                -o-transform: rotate(-15deg);
                -webkit-transform: rotate(-15deg);
                transform: rotate(-15deg);
              "
            />
            <div style="clear: both"></div>
            <div style="margin-bottom: 1em">
              <h3>Taller:</h3>
              <v-autocomplete
                v-model="idTaller"
                :items="workshops"
                :item-text="(item) => `${item.centro} | ${item.fecha.replace(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}:\d{2})$/, '$3/$2/$1 $4')} | ${item.nombre}`"
                item-value="id"
                flat
                hide-no-data
                label="Selecciona taller"
                solo-inverted
              ></v-autocomplete>
            </div>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title
                  >Gracias por tu opinión
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-layout>
                    <v-flex xs12>
                      <span>¿El taller ha respondido a tus expectativas?</span>
                      <v-rating
                        v-model="starValor1"
                        required
                        color="yellow darken-3"
                        background-color="grey darken-1"
                        empty-icon="$ratingFull"
                        hover
                        large
                      ></v-rating>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12>
                      <span
                        >¿Cuál ha sido el nivel de conocimientos del
                        profesor?</span
                      >
                      <v-rating
                        v-model="starValor2"
                        required
                        color="yellow darken-3"
                        background-color="grey darken-1"
                        empty-icon="$ratingFull"
                        hover
                        large
                      ></v-rating>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12>
                      <span
                        >¿Te ha gustado la forma en la que se ha
                        impartido?</span
                      >
                      <v-rating
                        v-model="starValor3"
                        required
                        color="yellow darken-3"
                        background-color="grey darken-1"
                        empty-icon="$ratingFull"
                        hover
                        large
                      ></v-rating>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12>
                      <v-text-field
                        v-model="starComentario"
                        label="Comentario"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-form>
                <h3>{{ message }}</h3>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="web" text color="tertiary"
                  >Volver a la web</v-btn
                >
                <v-btn @click="addComment" color="tertiary">Enviar</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { BASE_URL } from "./constants.js";
import { STYLE } from "./constants.js";
import * as basics from "./basics.js";
import genericReload from "./genericReload";
export default {
  name: "Valora",
  data: () => ({
    value: true,
    message: "",
    starComentario: "",
    starValor1: 0,
    starValor2: 0,
    starValor3: 0,
    idTaller: 0,
    workshops: [],
  }),
  async mounted() {
    const response = await genericReload(null, "api/withoutTokenWorkshop/?fechaInicio="+basics.getDate('date', -1)+"&fechaFin="+basics.getDate('date', 1));
    if (response.status === 200) {
      this.workshops = await response.json();
    }
  },
  methods: {
    async addComment() {
      if (this.starValor1 > 0 && this.starValor2 > 0 && this.starValor3 > 0) {
        const postData = {
          idTaller: this.idTaller,
          valor1: this.starValor1,
          valor2: this.starValor2,
          valor3: this.starValor3,
          comentario: this.starComentario,
        };
        const headers = {
          "Content-Type": "application/json",
        };

        const response = await fetch(`${BASE_URL}/api/withoutTokenWorkshop`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(postData),
        });

        switch (response.status) {
          case 200:
            this.message = "¡Gracias! Tu valoración ha sido registrada";
            this.starValor1 = 0;
            this.starValor2 = 0;
            this.starValor3 = 0;
            this.starComentario = "";
            break;
          default:
            this.message =
              "Error al registrar valoración. ¿Nos puedes informar sobre este error? Gracias";
            break;
        }
      } else {
        this.message = "No has completado alguno de los campos.";
      }
    },
    web() {
      window.open("https://www.lahabitacionsaludable.com");
    },
  },
};
</script>
<style scoped>
h1 {
  color: #fff;
  text-align: center;
  padding-bottom: 5%;
}
main {
  background-color: #31e9f5;
  background-size: cover;
}
h4 {
  display: inline-block;
  color: white;
  font-size: 2em;
  text-shadow: 0.03em 0.03em 0 rgb(0, 0, 0);
  filter: drop-shadow(2px 4px 8px #000);
}
</style>

<template>
  <v-app>
    <v-main>
      <v-container>
        <v-layout class="d-flex justify-center align-center historyTitle">
          <v-flex xs12 class="text-center">
            <h1 v-if="dataClient.apellidos && dataClient.nombre">
              {{ dataClient.apellidos + ", " + dataClient.nombre }}
            </h1>
          </v-flex>
        </v-layout>
        <v-layout class="d-flex justify-center align-center">
          <v-img
            alt="LHS Logo"
            class="shrink mr-2"
            contain
            src="@/assets/logo2.png"
            transition="scale-transition"
            width="150"
          />
        </v-layout>
        <v-layout class="space">
          <v-flex xs2>
            <v-text-field
              autocomplete="off"
              v-model="dataClient.nombre"
              label="Nombre"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              autocomplete="off"
              v-model="dataClient.apellidos"
              label="Apellidos"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              v-model="dataClient.fnac"
              label="Fecha de nacimiento"
              type="date"
              @blur="getAge()"
            ></v-text-field>
          </v-flex>
          <v-flex xs1>
            <v-text-field
              autocomplete="off"
              v-model="age"
              type="number"
              label="Edad"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-select
              v-model="dataClient.sexo"
              :items="sexos"
              label="Sexo"
            ></v-select>
          </v-flex>
          <v-flex xs3>
            <v-text-field
              autocomplete="off"
              v-model="dataClient.email"
              label="Email"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout v-if="age < 19 && age !== ''">
          <v-flex xs4>
            <v-text-field
              autocomplete="off"
              v-model="dataClient.nombreContacto"
              label="Nombre del autorizado"
            ></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-text-field
              autocomplete="off"
              v-model="dataClient.emailContacto"
              label="Email del autorizado"
            ></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-text-field
              autocomplete="off"
              v-model="dataClient.telefonoContacto"
              label="Teléfono del autorizado"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs3>
            <v-select
              v-model="dataClient.intereses"
              :items="this.$store.state.interestedIn"
              item-value="id"
              item-text="nombre"
              label="¿En qué estás interesado/a?"
              multiple
            ></v-select>
          </v-flex>
          <v-flex xs2>
            <v-select
              v-model="dataClient.orientacion"
              :items="orientacionItems"
              label="Orientación"
            ></v-select>
          </v-flex>
          <v-flex xs2>
            <v-select
              v-model="dataClient.conoce"
              :items="conocePorItems"
              label="¿Cómo nos conoció?"
            ></v-select>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              v-model="dataClient.recomendado"
              v-if="dataClient.conoce == 'Recomendado / Derivado'"
              label="¿Quién le recomendó?"
            ></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-text-field
              autocomplete="off"
              v-model="dataClient.telefono"
              type="tel"
              label="Teléfono"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs2>
            <v-select
              v-model="dataClient.tipo"
              :items="this.$store.state.typeClient"
              item-value="nombre"
              item-text="nombre"
              label="Tipo de cliente"
            ></v-select>
          </v-flex>
          <v-flex xs2>
            <v-select
              v-model="dataClient.idCentro"
              :items="this.$store.state.allCenters"
              item-value="id"
              item-text="nombre"
              label="Centro"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-spacer></v-spacer>
          <v-flex xs2>
            <v-checkbox
              v-model="dataClient.lopd"
              label="Consentimiento Newsletter"
            ></v-checkbox>
          </v-flex>
          <v-flex xs2>
            <v-checkbox
              v-model="dataClient.fotos"
              label="Consentimiento para fotos"
            ></v-checkbox>
          </v-flex>
          <v-flex xs2>
            <v-checkbox
              v-model="dataClient.valora"
              label="Envío valoración"
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <v-layout class="space">
          <v-flex xs4>
            <strong>Citas canceladas: {{  citasCanceladas }}</strong>
          </v-flex>
          <v-flex xs4>
            <strong>Citas cambiadas: {{  citasCambiadas }}</strong>
          </v-flex>
          <v-flex xs4>
            <strong>Citas a las que no asiste: {{  citasNoAsiste }}</strong>
          </v-flex>
        </v-layout>
        <v-layout class="space">
          <strong>Sus citas:</strong>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12>
            <v-data-table
              :headers="[
                { text: 'Fecha', value: 'fecha' },
                { text: 'Hora', value: 'hora' },
                { text: 'Tipo', value: 'tipo' },
                { text: 'Profesional', value: 'profesional' },
                { text: 'Modo', value: 'modo' },
                { text: 'Acciones', value: 'actions', sortable: false },
              ]"
              :items="nextAppointment"
              class="elevation-1"
              dense
            >
              <template v-slot:item.fecha="{ item }">
                {{ item.fecha }}
              </template>

              <template v-slot:item.hora="{ item }">
                {{ item.hora }}
              </template>

              <template v-slot:item.tipo="{ item }">
                {{ item.tipo }}
              </template>

              <template v-slot:item.profesional="{ item }">
                {{ item.profesional }}
              </template>

              <template v-slot:item.modo="{ item }">
                {{ item.modo }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn @click="editAppointment(item.id)" icon text>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="deleteAppointment(item)" icon text>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn @click="sendEventMail(item)" icon text>
                  <v-icon>mdi-email</v-icon>
                </v-btn>
                <v-btn @click="sendEventWhatsapp(item)" icon text>
                  <v-icon>mdi-whatsapp</v-icon>
                </v-btn>
                <v-btn @click="sendEventSMS(item)" icon text>
                  <v-icon>mdi-cellphone-message</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
        <v-layout class="space">
          <strong>Sus consultas:</strong>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12>
            <v-data-table
              :headers="[
                { text: 'Fecha', value: 'fecha' },
                { text: 'Tipo', value: 'tipo' },
                { text: 'Acciones', value: 'actions', sortable: false },
              ]"
              :items="consultas"
              class="elevation-1"
              dense
            >
              <template v-slot:item.fecha="{ item }">
                <span>{{ item.fecha }}</span>
              </template>

              <template v-slot:item.tipo="{ item }">
                <h4>{{ item.tipo }}</h4>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn icon @click="eliminaConsulta(item.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
        <v-layout class="space">
          <strong>Su asistencia a talleres:</strong>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12>
            <v-data-table
              :headers="[
                { text: 'Fecha de pago', value: 'fecha' },
                { text: 'Taller', value: 'taller' },
                { text: 'Acciones', value: 'actions', sortable: false },
              ]"
              :items="talleres"
              class="elevation-1"
              dense
            >
              <template v-slot:item.fecha="{ item }">
                <span>{{ item.fecha }}</span>
              </template>

              <template v-slot:item.taller="{ item }">
                <h4>{{ item.taller }}</h4>
              </template>

              <template v-slot:item.comentario="{ item }">
                <span>{{ item.comentario }}</span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn icon @click="eliminaTaller(item.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
        <v-layout class="space">
          <strong>Pagos realizados:</strong>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12>
            <v-data-table
              :headers="[
                { text: 'Fecha de pago', value: 'date' },
                { text: 'Evento', value: 'text' },
                { text: 'Acciones', value: 'actions', sortable: false },
              ]"
              :items="eventsLog"
              class="elevation-1"
              dense
            >
              <template v-slot:item.date="{ item }">
                <span
                  :style="
                    item.text.indexOf('Pendiente de pago') > -1
                      ? 'color: red'
                      : ''
                  "
                >
                  {{ item.date }}
                </span>
              </template>

              <template v-slot:item.text="{ item }">
                <h4
                  :style="
                    item.text.indexOf('Pendiente de pago') > -1
                      ? 'color: red'
                      : ''
                  "
                >
                  {{ item.text }}
                </h4>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  v-if="
                    item.profesional == dataClient.id ||
                    typeUser == 'admin' ||
                    typeUser == 'coord'
                  "
                  icon
                  @click="editEventsLog(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
        <showDialog ref="dialog" />
        <showMessage ref="snackbar" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { BASE_URL } from "./constants.js";
import genericReload from "./genericReload";
import showMessage from "./Messages.vue";
import showDialog from "./Dialog.vue";
import * as basics from "./basics.js";
export default {
  name: "ClientViewer",
  data: () => ({
    items: [],
    age: "",
    sexos: ["Femenino", "Masculino"],
    orientacionItems: ["Omnívora", "Vegana", "Vegetariana"],
    conocePorItems: [
      "Recomendado / Derivado",
      "Internet",
      "Pasé por la puerta",
      "Conocido / Familiar",
    ],
    nextAppointment: [],
    eventsLog: [],
    talleres: [],
    consultas: [],
    citasCambiadas: 0,
    citasCanceladas: 0,
    citasNoAsiste: 0,
    dataClient: {
      id: 0,
      nombre: "",
      apellidos: "",
      fnac: "",
      sexo: "",
      email: "",
      nombreContacto: "",
      emailContacto: "",
      telefonoContacto: "",
      intereses: [],
      orientacion: "",
      conoce: "",
      recomendado: "",
      telefono: "",
      tipo: "",
      idCentro: "",
      lopd: false,
      fotos: false,
      valora: false,
    },
  }),
  components: {
    showMessage,
    showDialog,
  },
  created() {
    this.typeUser = this.$store.state.typeUser;
    this.get = this.$store.getters;
  },
  async mounted() {
    const idClient = JSON.parse(localStorage.getItem("items"));

    const responseClient = await genericReload(
      this.$store.state.auth,
      "api/client/" + idClient
    );
    const jsonClient = await responseClient.json();
    this.dataClient = jsonClient[0];
    this.dataClient.intereses = jsonClient[1];
    this.items = idClient;
    this.getAge();
    this.changeDateFormatted("date");
    await this.cargaCitas();
    await this.cargaCuotas();
    await this.cargaTalleres();
    await this.cargaConsultas();
    await this.loadStatisticsAppointment();
  },
  methods: {
    printDocument() {
      setTimeout(() => {
        window.print();
        window.close();
      }, 500);
    },
    getAge() {
      if (this.dataClient.fnac) {
        let hoy = new Date();
        let fnac = new Date(this.dataClient.fnac);
        let edad = hoy.getFullYear() - fnac.getFullYear();
        let m = hoy.getMonth() - fnac.getMonth();

        if (m < 0 || (m === 0 && hoy.getDate() < fnac.getDate())) {
          edad--;
        }
        this.age = edad;
      }
    },
    changeDateFormatted(field) {
      switch (field) {
        case "date":
          if (this.dataClient.fnac) {
            this.dataClient.dateFormatted = basics.formatDate(
              this.dataClient.fnac,
              "dd/mm/yyyy"
            );
          }
          break;
      }
    },
    async loadStatisticsAppointment() {
      this.nextAppointment = [];
      let ruta = "api/appointment/?idCliente=" + this.dataClient.id+"&borrado=on";
      const response = await genericReload(this.$store.state.auth, ruta);
      if (response.status === 200) {
        const json = await response.json();
        const estadosContador = json.reduce(
          (contador, registro) => {
            if (
              ["No asiste", "Cancelada", "Cambiada"].includes(registro.estado)
            ) {
              contador[registro.estado] = (contador[registro.estado] || 0) + 1;
            }
            return contador;
          },
          { "No asiste": 0, Cancelada: 0, Cambiada: 0 }
        );
        this.citasCambiadas = estadosContador["Cambiada"];
        this.citasCanceladas = estadosContador["Cancelada"];
        this.citasNoAsiste = estadosContador["No asiste"];
      }
    },
    async cargaCitas() {
      this.nextAppointment = [];
      let ruta =
        "api/appointment/?idCliente=" +
        this.dataClient.id
      const response = await genericReload(this.$store.state.auth, ruta);
      if (response.status === 200) {
        const json = await response.json();
        let self = this;
        setTimeout(function () {
          for (let i = 0; i < json.length; i++) {
            let [date, time] = json[i].fecha.split(" ");
            let tipo = json[i].tipo;
            let profesional = self.get.professionalById(
              json[i].idProfesional
            ).nombre;
            let modo = json[i].modo;
            let id = json[i].id;
            let centro = self.get.centerById(
              self.dataClient.idCentro
            ).nombre;
            if (modo == "Online") {
              centro = modo;
            }
            const mail =
              "Hola " +
              self.get.clientById(self.dataClient.id).nombre +
              ", desde LHS te recordamos tu cita del día " +
              basics.formatDate(date, "dd/mm/yyyy") +
              " a las " +
              time +
              ".<br><br><b>CONSULTA: </b>" +
              tipo +
              ".</b><br><b>CENTRO: </b>" +
              centro +
              ".<br><b>PROFESIONAL: </b>" +
              profesional;
            self.nextAppointment.push({
              id: id,
              fecha: basics.formatDate(date, "dd/mm/yyyy"),
              hora: time,
              tipo: tipo,
              profesional: profesional,
              modo: modo,
              idProfesional: json[i].idProfesional,
              mail: mail,
            });
          }
        }, 2000);
      }
    },
    async cargaCuotas() {
      this.eventsLog = [];
      const response = await genericReload(
        this.$store.state.auth,
        "api/fee/?idCliente=" + this.dataClient.id
      );
      if (response.status === 200) {
        const json = await response.json();
        for (let i = 0; i < json.length; i++) {
          this.eventsLog.push({
            date: basics.formatDate(json[i].fecha, "dd/mm/yyyy"),
            text:
              json[i].nombre +
              " | " +
              (parseFloat(json[i].precio) -
                parseFloat(json[i].precio) *
                  (parseFloat(json[i].pdescuento) / 100) -
                parseFloat(json[i].descuento)) +
              "€ | " +
              json[i].pago +
              " | " +
              json[i].comentario,
            pdescuento: json[i].pdescuento,
            descuento: json[i].descuento,
            profesional: json[i].idProfesional,
            codigo: json[i].codigo,
            pago: json[i].pago,
            id: json[i].id,
          });
        }
      }
    },
    async cargaTalleres() {
      this.talleres = [];
      const response = await genericReload(
        this.$store.state.auth,
        "api/clientWorkshop/?idCliente=" + this.dataClient.id
      );
      if (response.status === 200) {
        const json = await response.json();
        let self = this;
        setTimeout(function () {
          for (let i = 0; i < json.length; i++) {
            self.talleres.push({
              id: json[i].id,
              fecha: basics.formatDate(json[i].fecha, "dd/mm/yyyy"),
              taller: self.get.workshopById(json[i].idTaller).nombre,
            });
          }
        }, 2000);
      }
    },
    async cargaConsultas() {
      this.consultas = [];
      const response = await genericReload(
        this.$store.state.auth,
        "api/consultation/?idCliente=" + this.dataClient.id
      );
      if (response.status === 200) {
        const json = await response.json();
        let self = this;
        setTimeout(function () {
          for (let i = 0; i < json.length; i++) {
            self.consultas.push({
              id: json[i].id,
              fecha: basics.formatDate(json[i].fecha, "dd/mm/yyyy"),
              tipo: json[i].tipo.toUpperCase(),
              comentario: json[i].comentario,
            });
          }
        }, 2000);
      }
    },
    async eliminaTaller(item) {
      this.$refs.dialog
        .setNew(
          "Eliminar registro",
          "¿Estás seguro de querer eliminar este registro?",
          0
        )
        .then(async (confirm) => {
          if (confirm.response) {
            const headers = {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.$store.state.auth,
            };

            const response = await fetch(
              `${BASE_URL}/api/clientWorkshop/${item}`,
              {
                method: "DELETE",
                headers: headers,
              }
            );
            switch (response.status) {
              case 200:
                this.$refs.snackbar.setNew(
                  true,
                  "Registro eliminado",
                  "Ok",
                  "green"
                );
                this.cargaTalleres();
                break;
              default:
                this.$refs.snackbar.setNew(
                  true,
                  "ERROR. El registro NO se ha eliminado",
                  "Cerrar",
                  "red"
                );
                break;
            }
          } else {
            this.$refs.snackbar.setNew(
              true,
              "El registro NO se ha eliminado",
              "Cerrar",
              "red"
            );
          }
        });
    },
    async eliminaConsulta(item) {
      this.$refs.dialog
        .setNew(
          "Eliminar registro",
          "¿Estás seguro de querer eliminar este registro?",
          0
        )
        .then(async (confirm) => {
          if (confirm.response) {
            const headers = {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.$store.state.auth,
            };

            const response = await fetch(
              `${BASE_URL}/api/consultation/${item}`,
              {
                method: "DELETE",
                headers: headers,
              }
            );
            switch (response.status) {
              case 200:
                this.$refs.snackbar.setNew(
                  true,
                  "Registro eliminado",
                  "Ok",
                  "green"
                );
                this.cargaConsultas();
                break;
              default:
                this.$refs.snackbar.setNew(
                  true,
                  "ERROR. El registro NO se ha eliminado",
                  "Cerrar",
                  "red"
                );
                break;
            }
          } else {
            this.$refs.snackbar.setNew(
              true,
              "El registro NO se ha eliminado",
              "Cerrar",
              "red"
            );
          }
        });
    },
    async editAppointment(id) {
      let ruta = "api/appointment/" + id;
      const responseA = await genericReload(this.$store.state.auth, ruta);
      if (responseA.status === 200) {
        const jsonA = await responseA.json();
        let [date, time] = jsonA[0].fecha.split(" ");
        let [dateEnd, timeEnd] = jsonA[0].fechaFin.split(" ");
        this.$refs.dialog
          .setNew("Editar cita", "", "citas", id, {
            date: date,
            time: time,
            timeEnd: timeEnd,
            patient: this.dataClient.id,
            professional: jsonA[0].idProfesional,
            center: jsonA[0].idCentro,
            specialty: jsonA[0].tipo,
            mode: jsonA[0].modo,
            remember: jsonA[0].recordar,
          })
          .then(async (confirm) => {
            if (confirm.response) {
              if (
                confirm.recurrency == "No recurrente" ||
                confirm.recurrency == undefined
              ) {
                const putData = {
                  fecha: confirm.date + " " + confirm.time,
                  fechaFin: confirm.date + " " + confirm.timeEnd,
                  idCliente: confirm.patient,
                  idCentro: confirm.center,
                  idProfesional: confirm.professional,
                  tipo: confirm.specialty,
                  modo: confirm.mode,
                  recordar: confirm.remember,
                  estado: "Cambiada",
                };
                const headers = {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + this.$store.state.auth,
                };

                const response = await fetch(
                  `${BASE_URL}/api/appointment/${id}`,
                  {
                    method: "PUT",
                    headers: headers,
                    body: JSON.stringify(putData),
                  }
                );
                switch (response.status) {
                  case 200:
                    this.$refs.snackbar.setNew(
                      true,
                      "Cita creada correctamente.",
                      "Ok",
                      "green"
                    );
                    this.cargaCitas();
                    this.citaAsignada = true;
                    break;
                  default:
                    this.$refs.snackbar.setNew(
                      true,
                      "ERROR. La cita NO se ha modificado",
                      "Cerrar",
                      "red"
                    );
                    break;
                }
              } else {
                if (confirm.dateRecurrency != undefined) {
                  let days = 0;
                  if (confirm.recurrency == "Cada semana") {
                    days = 7;
                  }
                  if (confirm.recurrency == "Cada dos semanas") {
                    days = 14;
                  }
                  if (confirm.recurrency == "Cada tres semanas") {
                    days = 21;
                  }
                  if (confirm.recurrency == "Cada cuatro semanas") {
                    days = 28;
                  }
                  const dates = basics.recurringDates(
                    confirm.date,
                    confirm.dateRecurrency,
                    days,
                    1
                  );
                  let postData, response;
                  const headers = {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.$store.state.auth,
                  };
                  for (let i = 0; i < dates.length; i++) {
                    postData = {
                      fecha: dates[i] + " " + confirm.time,
                      fechaFin: dates[i] + " " + confirm.timeEnd,
                      idCliente: confirm.patient,
                      idCentro: confirm.center,
                      idProfesional: confirm.professional,
                      tipo: confirm.specialty,
                      modo: confirm.mode,
                      recordar: confirm.remember,
                    };

                    response = await fetch(`${BASE_URL}/api/appointment`, {
                      method: "POST",
                      headers: headers,
                      body: JSON.stringify(postData),
                    });
                  }
                  switch (response.status) {
                    case 200:
                      this.$refs.snackbar.setNew(
                        true,
                        "Citas creadas",
                        "Ok",
                        "green"
                      );
                      this.cargaCitas();
                      break;
                    default:
                      this.$refs.snackbar.setNew(
                        true,
                        basics.returnError(response.status),
                        "Cerrar",
                        "red"
                      );
                      break;
                  }
                } else {
                  this.$refs.snackbar.setNew(
                    true,
                    "Las citas NO se han modificado. No ha indicado hasta que fecha existirá la recurrencia de la cita.",
                    "Cerrar",
                    "red"
                  );
                }
              }
            } else {
              this.$refs.snackbar.setNew(
                true,
                "La cita NO se ha modificado",
                "Cerrar",
                "red"
              );
            }
          });
      }
    },
    async deleteAppointment(item) {
      this.$refs.dialog
        .setNew(
          "Eliminar cita",
          "",
          "deleteAppointment"
        )
        .then(async (confirm) => {
          if (confirm.response) {
            const headers = {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.$store.state.auth,
            };

            const response = await fetch(
              `${BASE_URL}/api/appointment/${item.id}?borrado=${confirm.reason}`,
              {
                method: "DELETE",
                headers: headers,
              }
            );
            switch (response.status) {
              case 200:
                this.$refs.snackbar.setNew(
                  true,
                  "Cita eliminada",
                  "Ok",
                  "green"
                );
                this.cargaCitas();
                break;
              default:
                this.$refs.snackbar.setNew(
                  true,
                  basics.returnError(response.status),
                  "Cerrar",
                  "red"
                );
                break;
            }
          } else {
            this.$refs.snackbar.setNew(
              true,
              "La cita NO se ha eliminado",
              "Cerrar",
              "red"
            );
          }
        });
    },
    sendEventMail(item) {
      this.$refs.dialog
        .setNew(
          "Envío de recordatorio",
          "Se le notificará al cliente su cita. ¿Estás seguro de querer hacer el envío?",
          ""
        )
        .then(async (confirm) => {
          if (confirm.response) {
            const postData = {
              email: this.dataClient.email,
              content: item.mail,
              subject: "Recordatorio de cita",
            };

            const headers = {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.$store.state.auth,
            };

            const response = await fetch(`${BASE_URL}/api/mailing`, {
              method: "POST",
              headers: headers,
              body: JSON.stringify(postData),
            });
            switch (response.status) {
              case 200:
                const putData = {
                  recordado: 1,
                };
                const headers2 = {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + this.$store.state.auth,
                };

                const response2 = await fetch(
                  `${BASE_URL}/api/appointment/${item.id}`,
                  {
                    method: "PUT",
                    headers: headers2,
                    body: JSON.stringify(putData),
                  }
                );
                this.$refs.snackbar.setNew(
                  true,
                  "Mensaje enviado correctamente",
                  "Ok",
                  "green"
                );
                break;
              default:
                this.$refs.snackbar.setNew(
                  true,
                  "ERROR. Mensaje no enviado",
                  "Cerrar",
                  "red"
                );
                break;
            }
          } else {
            this.$refs.snackbar.setNew(
              true,
              "Mensajes no enviados",
              "Cerrar",
              "red"
            );
          }
        });
    },
    sendEventWhatsapp(item) {
      this.$refs.dialog
        .setNew(
          "Envío de recordatorio",
          "Se le notificará al cliente su cita. ¿Estás seguro de querer hacer el envío?",
          ""
        )
        .then(async (confirm) => {
          if (confirm.response) {
            let phone = this.dataClient.telefono;
            let content = item.mail;
            content = content.split("<br>").join(" ");
            content = content.split("<b>").join("");
            content = content.split("</b>").join("");
            let msg = content.replace(" ", "%20");
            let url = "";
            if (phone.indexOf("+") > -1) {
              url =
                "https://api.whatsapp.com/send?phone=" + phone + "&text=" + msg;
            } else {
              url =
                "https://api.whatsapp.com/send?phone=+34" +
                phone +
                "&text=" +
                msg;
            }

            window.open(url);

            const headers = {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.$store.state.auth,
            };

            const putData = {
              recordado: 1,
            };

            const response2 = await fetch(
              `${BASE_URL}/api/appointment/${item.id}`,
              {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(putData),
              }
            );
          } else {
            this.$refs.snackbar.setNew(
              true,
              "Mensaje no enviados",
              "Cerrar",
              "red"
            );
          }
        });
    },
    sendEventSMS(item) {
      this.$refs.dialog
        .setNew(
          "Envío de recordatorio",
          "Se le notificará al cliente su cita. ¿Estás seguro de querer hacer el envío?",
          ""
        )
        .then(async (confirm) => {
          if (confirm.response) {
            let phone = this.dataClient.telefono;
            let content = item.mail;
            content = content.split("<br>").join(" ");
            content = content.split("<b>").join("");
            content = content.split("</b>").join("");
            if (phone.indexOf("+") > -1) {
              phone = phone.split("+").join("");
            } else {
              phone = "34" + phone;
            }

            const postData = {
              phone: phone,
              content: content,
            };

            const headers = {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.$store.state.auth,
            };

            const response = await fetch(`${BASE_URL}/api/sms`, {
              method: "POST",
              headers: headers,
              body: JSON.stringify(postData),
            });
            switch (response.status) {
              case 200:
                const putData = {
                  recordado: 1,
                };
                const headers2 = {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + this.$store.state.auth,
                };

                const response2 = await fetch(
                  `${BASE_URL}/api/appointment/${item.id}`,
                  {
                    method: "PUT",
                    headers: headers2,
                    body: JSON.stringify(putData),
                  }
                );
                this.$refs.snackbar.setNew(
                  true,
                  "Mensaje enviado correctamente",
                  "Ok",
                  "green"
                );
                break;
              default:
                this.$refs.snackbar.setNew(
                  true,
                  "ERROR. Mensaje no enviado",
                  "Cerrar",
                  "red"
                );
                break;
            }
          }
        });
    },
    async editEventsLog(item) {
      this.$refs.dialog
        .setNew("Editar dato económico", "", "editEventLog", 0, item)
        .then(async (confirm) => {
          if (confirm.response) {
            const headers = {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.$store.state.auth,
            };
            const putData = {
              fecha: confirm.fecha,
              pago: confirm.pago,
              codigo: confirm.codigo,
              comentario: confirm.comentario,
              idProfesional: confirm.profesional,
            };
            const response = await fetch(`${BASE_URL}/api/fee/${item.id}`, {
              method: "PUT",
              headers: headers,
              body: JSON.stringify(putData),
            });
            switch (response.status) {
              case 200:
                this.$refs.snackbar.setNew(
                  true,
                  "Registro económico editado",
                  "Ok",
                  "green"
                );
                if (confirm.pago == "Cartera") {
                  const postData = {
                    cliente: this.dataClient.id,
                    codigo: confirm.codigo,
                    importe: confirm.precio,
                    tipo: "gasto",
                  };

                  await fetch(`${BASE_URL}/api/wallet`, {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(postData),
                  });
                }
                if (item.pago == "Cartera" && confirm.pago != "Cartera") {
                  const postData = {
                    cliente: this.dataClient.id,
                    codigo: confirm.codigo,
                    importe: confirm.precio,
                    tipo: "ingreso",
                  };

                  await fetch(`${BASE_URL}/api/wallet`, {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(postData),
                  });
                }
                this.cargaCuotas();
                break;
              default:
                this.$refs.snackbar.setNew(
                  true,
                  "ERROR. El registro económico NO se ha editado",
                  "Cerrar",
                  "red"
                );
                break;
            }
          } else {
            this.$refs.snackbar.setNew(
              true,
              "El registro económico NO se ha editado",
              "Cerrar",
              "red"
            );
          }
        });
    },
  },
};
</script>

<style scoped>
.historyTitle {
  margin-bottom: 20px;
}
.space {
  margin-top: 70px;
}
</style>

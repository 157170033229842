<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-layout align-center justify-center>
          <v-flex xs12 sm10 md8>
            <h1
              style="
                text-align: left;
                float: left;
                padding-right: 2em;
                font-size: 2.5em;
                filter: drop-shadow(2px 4px 8px #585858);
              "
            >
              <span style="color: black"
                >Entrar en el Club LHS<br />tiene premio</span
              ><br /><span style="color: white;"
                >¿QUÉ PREFIERES?
                <v-select
                  v-model="post.regalo"
                  :items="regalos"
                  label="Selecciona tu regalo"
                ></v-select
              ></span>
            </h1>
            <v-img
              class="shrink mr-2"
              contain
              src="@/assets/flecha.png"
              transition="scale-transition"
              width="150"
              style="
                filter: drop-shadow(2px 4px 8px #585858);
                -moz-transform: rotate(-25deg);
                -o-transform: rotate(-25deg);
                -webkit-transform: rotate(-25deg);
                transform: rotate(-25deg);
              "
            />
            <div style="clear: both"></div>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title
                  >Déjanos tus datos y consigue tu regalo totalmente GRATIS
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-layout wrap>
                    <v-flex xs12 md3 sm12>
                      <v-text-field
                        label="Nombre"
                        v-model="post.nombre"
                        :rules="rules.nombre"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 sm12>
                      <v-text-field
                        label="Apellidos"
                        v-model="post.apellidos"
                        :rules="rules.apellidos"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 sm12>
                      <v-text-field
                        label="Email"
                        v-model="post.email"
                        :rules="rules.email"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 sm12>
                      <v-text-field
                        autocomplete="off"
                        v-model="post.telefono"
                        type="tel"
                        label="Teléfono"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 md3 sm12>
                      <v-menu
                        v-model="menuDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            autocomplete="off"
                            v-model="dateFormatted"
                            label="Fecha de nacimiento"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('date')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="post.fnac"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 md3 sm12>
                      <v-select
                        v-model="centro"
                        :items="centros"
                        label="Centro más cercano"
                      ></v-select>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 md3 sm12>
                      <v-checkbox
                        v-model="post.lopd"
                        label="¿Quieres recibir nuestra newsletter?"
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                </v-form>
                <h3>{{ message }}</h3>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="web" text color="tertiary"
                  >Volver a la web</v-btn
                >
                <v-btn @click="add" color="tertiary">Unirme</v-btn>
              </v-card-actions>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <b>¿QUÉ PASARÁ CON TUS DATOS?</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Para tu tranquilidad, en la La Habitación Saludable nos
                    tomamos tu confianza muy en serio y por eso cumplimos con
                    Reglamento General de Protección de Datos 2016/679 (RGPD).
                    Te informamos que los datos solicitados y facilitados serán
                    incorporados a un fichero bajo la responsabilidad de AZAHAR
                    SALUD S.L., empresa propietaria de la marca comercial LA
                    HABITACIÓN SALUDABLE, con C.I.F. B91734376. Tus datos serán
                    gestionados con la finalidad de informar y comunicar sobre
                    todo aquello relativo a la prestación de nuestros servicios
                    profesionales y actividades relacionadas con Azahar Salud
                    S.L.<br /><br />
                    Al hacer clic en "UNIRME", aceptas que podamos procesar tus
                    datos tal y como te hemos contado. Si nos hacemos muy
                    “pesados”, cambias de opinión, quieres que cambiemos algún
                    dato, o simplemente ya no te interesa lo que te contamos,
                    puedes pedirnos que borremos tus datos de nuestro fichero o
                    los modifiquemos, mandándonos un email a
                    comunicacion@lahabitacionsaludable.com (o simplemente date
                    de baja haciendo clic en el enlace "cancela tu suscripción"
                    que hay en el pie de página de cualquier correo electrónico
                    nuestro que recibas). Para obtener más información acerca de
                    nuestras prácticas de privacidad, visita
                    www.lahabitacionsaludable.com.
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { BASE_URL } from "./constants.js";
import { STYLE } from "./constants.js";
import * as basics from "../components/basics.js";
import genericReload from "../components/genericReload";
export default {
  name: "ClubLHS",
  data: () => ({
    value: true,
    message: "",
    regalos: ["Evaluación nutricional", "Recetario"],
    menuDate: null,
    dateFormatted: null,
    idCentro: null,
    centro: null,
    centros: [
      "Granada",
      "Sevilla, Bermejales",
      "Sevilla, Bellavista",
      "Tomares (Sevilla)",
    ],
    rules: {
      nombre: [(val) => (val || "").length > 0 || "El nombre es obligatorio"],
      apellidos: [
        (val) => (val || "").length > 0 || "Los apellidos son obligatorios",
      ],
      email: [(val) => (val || "").length > 0 || "El email es obligatorio"],
    },
    post: {
      nombre: "",
      apellidos: "",
      fnac: "",
      lopd: 1,
      email: "",
      telefono: "",
      regalo: "",
    },
  }),
  methods: {
    async add() {
      if (basics.validEmail(this.post.email)) {
        switch (this.centro) {
          case "Granada":
            this.idCentro = 4;
            break;
          case "Sevilla, Bermejales":
            this.idCentro = 7;
            break;
          case "Sevilla, Bellavista":
            this.idCentro = 8;
            break;
          case "Tomares (Sevilla)":
            this.idCentro = 6;
            break;
        }
        const postData = {
          nombre: this.post.nombre,
          apellidos: this.post.apellidos,
          fnac: this.post.fnac,
          lopd: this.post.lopd,
          email: this.post.email,
          telefono: this.post.telefono,
          idCentro: this.idCentro,
        };
        const headers = {
          "Content-Type": "application/json",
        };

        const response = await fetch(`${BASE_URL}/api/withoutTokenClient`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(postData),
        });
        switch (response.status) {
          case 200:
            this.message =
              "¡Recibirás noticias nuestras muy pronto! Mira tu correo.";
            const headersEmail = {
              "Content-Type": "application/json",
            };
            let emailContent = "";
            let emailSubject = "";
            if (this.post.regalo == "Recetario") {
              emailContent =
                this.post.nombre.toUpperCase().bold() +
                " " +
                this.post.apellidos.toUpperCase().bold() +
                " se ha unido al Club LHS y ha recibido el código BIENVENIDA para descargar su recetario de la Tienda LHS.";
              emailSubject = "Nuevo cliente Club LHS";
            } else {
              emailContent =
                this.post.nombre.toUpperCase().bold() +
                " " +
                this.post.apellidos.toUpperCase().bold() +
                " se ha unido al Club LHS y ha solicitado una Valoración Nutricional en " +
                this.centro.toUpperCase().bold() +
                ". Su teléfono es " +
                this.post.telefono.bold();
              emailSubject = "Nueva VALORACIÓN NUTRICIONAL";
            }
            const emailData = {
              email: "comunicacion@lahabitacionsaludable.com",
              content: emailContent,
              subject: emailSubject,
            };
            await fetch(`${BASE_URL}/api/mailing`, {
              method: "POST",
              headers: headersEmail,
              body: JSON.stringify(emailData),
            });

            const getContent = await genericReload(
              "",
              "api/withoutTokenMessage"
            );
            const messages = await getContent.json();
            let load = null;
            if (this.post.regalo == "Recetario") {
              load = messages.find((message) => message.id === 12);
            } else {
              load = messages.find((message) => message.id === 20);
            }
            let finalContent = load.contenido.replace(
              "[[nombre]]",
              this.post.nombre
            );
            finalContent = finalContent.replace(
              "[[telefono]]",
              this.post.telefono
            );
            const content = finalContent;
            const subject = load.asunto;
            const emailClientData = {
              email: this.post.email,
              content: `${STYLE}` + content,
              subject: subject,
            };
            await fetch(`${BASE_URL}/api/mailing`, {
              method: "POST",
              headers: headersEmail,
              body: JSON.stringify(emailClientData),
            });
            break;
          case 400:
            this.message =
              "Error en el envío de datos. Por favor, ¿puedes comunicarnos este error por otro medio?";
            break;
          case 500:
            this.message =
              "Error en el envío de datos. Por favor, ¿puedes comunicarnos este error por otro medio?";
            break;
        }
      } else {
        this.message = "Por favor, inserte un email válido";
      }
    },
    web() {
      window.open("https://www.lahabitacionsaludable.com");
    },
    changeDateFormatted(field) {
      switch (field) {
        case "date":
          if (this.post.fnac) {
            this.dateFormatted = basics.formatDate(
              this.post.fnac,
              "dd/mm/yyyy"
            );
          }
          break;
      }
    },
  },
};
</script>
<style scoped>
h1 {
  color: #fff;
  text-align: center;
  padding-bottom: 5%;
}
main {
  background-color: #31e9f5;
  background-size: cover;
}
</style>
